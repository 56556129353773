import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";
export const ExperienceGridWeb = ({ data }) => {
  // console.log("🚀 ~ ExperienceGridWeb ~ data:", data);
  //console.log("ExperienceGrid", data);
  const navigate = useNavigate();
  const truncateText = (text, maxLength) => {
    return text?.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const { handleRequest } = useApi();
  /* Fav Icon Functionality */
  const [favorites, setFavorites] = useState([]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
    if (userDetails?.eventFav) {
      setFavorites(userDetails.eventFav);
    }
  }, []);
  // Function to toggle favorite status
  const toggleFavorite = (categoryId) => {
    const updatedFavorites = favorites.includes(categoryId)
      ? favorites.filter((id) => id !== categoryId)
      : [...favorites, categoryId];

    setFavorites(updatedFavorites);

    // Update localStorage
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      userDetails.eventFav = updatedFavorites;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }
    //console.log("Favorite updated for experience:", categoryId);
  };
  const isFavorite = favorites.includes(data?.category);
  return (
    <div className="f20-500 h-100 w-100 mt-4 position-relative">
      <div
        className="experience-card overlay shadow-sm"
        style={{
          borderRadius: "19px",
          background: `url('${data?.photo || "url"}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "300px", // Adjust height as needed
        }}
      ></div>
      <div className="experience-details d-flex justify-content-between align-items-center mt-2 p-2">
        <div>
          <div className="f16-600">{data?.title || " experience"}</div>
          <div className="f14-400 text-muted">
            {truncateText(data?.description || "Cuisine Info", 30)}
          </div>
        </div>
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <Image
            src={
              isFavorite
                ? require("../../assets/images/IconsandImages/heartcoloured.png")
                : require("../../assets/images/IconsandImages/heartcoloured.png")
            }
            alt="Heart"
            style={{
              width: 16,
              height: 16,
              cursor: "pointer",
            }}
            onClick={() => toggleFavorite(data?.category)}
          />
          <span className="f14-400 ">{data?.explike ? data.explike : "0"}</span>
          <Image
            src={require("../../assets/images/ExperiencePageAssets/eye.png")}
            alt="Star"
            style={{ width: 16, height: 16 }}
          />
          <span className="f14-400">{data?.expview ? data.expview : "0"}</span>
        </div>
        <button
          className="btn btn-danger py-2 text-white f16-500"
          style={{
            borderRadius: "12px",
            backgroundColor: "#F85032",
            padding: "6px 12px",
            fontSize: "14px",
          }}
          onClick={() => {
            sessionStorage.setItem("experienceData", JSON.stringify(data));
            // navigate(
            //   `/restaurant/${data?.restaurantId}/experienceweb/${data?._id}`
            // );
            navigate(
              `/restaurant/${data?.restaurantIds}/experienceweb/${data?._id}`
            );
          }}
        >
          Explore Now
        </button>
      </div>
    </div>
  );
};
