import React, { useState } from "react";
import PropTypes from "prop-types";
import SocketContext from "../../../setup/socket/SocketContext";
import ScreenTemplate from "../../../components/ScreenTemplate";
import MainSideBar from "../../../components/MainsideBar";
import Mainheader from "../../../components/headers/Mainheader";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { NOTAVAILABLEIMAGE } from "../../../common/enums/GlobalEnums";
import TitleSection from "../../ReusableComponent/TitleSection";

const OndcTrack = (props) => {
  const { ID, order_id } = useParams();
  const [loading, setLoading] = useState(true);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const content = (
    <Box>
      <div>
        <TitleSection
          title={"Test Restaurant" || ""}
          subtitle={`REQUEST ID: ${"123456" || ""}`}
          resticon={NOTAVAILABLEIMAGE}
          className={"box-shadow-down"}
          backRoute={`/restaurant/${"123456"}`}
          callicon={true}
        />
      </div>
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
};

OndcTrack.propTypes = {};

const OndcTrackScreen = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OndcTrack {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default OndcTrackScreen;
