import moment from "moment";
import { isEmpty } from "./helperFunc";

/**
 * ACTIVE BUTTON CHECK
 */
//
const activeButtonCheck = (data) => {
  // console.log("🚀 ~ activeButtonCheck ~ data", data);
  let expStartDate = !isEmpty(data) && data?.startDate;
  let expEndDate = !isEmpty(data) && data?.endDate;
  let maxDaysAhead = !isEmpty(data) && data?.maxDaysAhead;
  let dateFrom = moment(expStartDate)
    .subtract(maxDaysAhead, "d")
    .format("YYYY-MM-DD");
  let currentDate = moment().format("YYYY-MM-DD");
  let momentStartDate = moment(expStartDate).format("YYYY-MM-DD");
  let momentexpEndDate = moment(expEndDate).format("YYYY-MM-DD");
  let diff_bw_Start_End = moment(momentexpEndDate).diff(
    moment(momentStartDate),
    "days"
  );
  /**** SPECIAL FUNC ****/
  if (data?.expType === "SPECIAL") {
    if (currentDate > momentexpEndDate) {
      return true;
    } else if (+data?.seatsLeft <= 0) {
      return true;
    } else if (!data?.sameDayBooking) {
      if (!isEmpty(data?.maxDaysAhead)) {
        if (currentDate >= dateFrom) {
          if (dateFrom <= momentexpEndDate) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (diff_bw_Start_End === 0) {
          if (
            currentDate === momentStartDate &&
            currentDate === momentexpEndDate
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else if (!isEmpty(data?.maxDaysAhead)) {
      if (currentDate >= dateFrom) {
        if (dateFrom <= momentexpEndDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    /**** CURATED FUNC ****/
    if (currentDate > momentexpEndDate) {
      return true;
    } else if (+data?.seatsLeft <= 0) {
      return true;
    } else if (!data?.sameDayBooking) {
      if (diff_bw_Start_End === 0) {
        if (
          currentDate === momentStartDate &&
          currentDate === momentexpEndDate
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        // if (currentDate === momentexpEndDate) {
        //   return true;
        // } else {
        return false;
        // }
      }
    } else {
      return false;
    }
  }
};

/**
 * GET MINIMUM AMOUNT
 */
const getMinimumAmount = (data) => {
  const arrData = !isEmpty(data?.packages) && data?.packages;
  let minimumAmount = "";
  const lowestDiscount =
    arrData &&
    arrData.reduce((previous, current) => {
      return previous?.discountedPrice !== 0
        ? previous?.discountedPrice < current?.discountedPrice
          ? previous
          : current
        : current;
    });
  const lowestPrice =
    arrData &&
    arrData.reduce((previous, current) => {
      return previous?.price !== 0
        ? previous?.price < current?.price
          ? previous
          : current
        : current;
    });

  const index =
    arrData && arrData.findIndex((item) => item?._id === lowestDiscount?._id);
  const indexTwo =
    arrData && arrData.findIndex((item) => item?._id === lowestPrice?._id);

  let expData = !isEmpty(data) && data;
  if (expData?.isPackagesProvided === true) {
    if (expData?.packages.length > 0) {
      for (let i = 0; i < expData?.packages.length; i++) {
        if (expData?.packages?.[i]?.status) {
          for (let i = 0; i < arrData.length; i++) {
            if (lowestDiscount) {
              if (
                arrData?.[index]?.status &&
                !isEmpty(arrData?.[index]?.discountedPrice)
              ) {
                minimumAmount =
                  arrData?.[index]?.discountedPrice === 0
                    ? data?.adultPrice !== 0
                      ? arrData?.[indexTwo]?.price < data?.adultPrice
                        ? arrData?.[indexTwo]?.price
                        : data?.adultPrice
                      : arrData?.[indexTwo]?.price
                    : data?.adultPrice !== 0
                    ? arrData?.[index]?.discountedPrice < data?.adultPrice
                      ? arrData?.[index]?.discountedPrice
                      : data?.adultPrice
                    : arrData?.[index]?.discountedPrice;
              } else {
                minimumAmount = arrData?.[indexTwo]?.price;
              }
            } else {
              if (
                arrData?.[i]?.status &&
                !isEmpty(arrData?.[i]?.discountedPrice)
              ) {
                minimumAmount =
                  arrData?.[i]?.discountedPrice === 0
                    ? data?.adultPrice !== 0
                      ? arrData?.[indexTwo]?.price < data?.adultPrice
                        ? arrData?.[indexTwo]?.price
                        : data?.adultPrice
                      : arrData?.[indexTwo]?.price
                    : data?.adultPrice !== 0
                    ? arrData?.[index]?.discountedPrice < data?.adultPrice
                      ? arrData?.[index]?.discountedPrice
                      : data?.adultPrice
                    : arrData?.[index]?.discountedPrice;
              } else {
                minimumAmount = arrData?.[i]?.price;
              }
            }
          }
        } else {
          minimumAmount = data?.adultPrice;
        }
      }
    }
  } else {
    minimumAmount = data?.adultPrice;
  }
  return minimumAmount;
};

/**
 * GET DISCOUNT AMOUNT
 */
const getDiscountedAmount = (data) => {
  const arrData = !isEmpty(data?.packages) && data?.packages;
  let minimumAmount = "";
  const lowestDiscount =
    arrData &&
    arrData.reduce((previous, current) => {
      return previous.discountedPrice !== 0
        ? previous.discountedPrice < current.discountedPrice
          ? previous
          : current
        : current;
    });

  const index =
    arrData && arrData.findIndex((item) => item?._id === lowestDiscount._id);
  let expData = !isEmpty(data) && data;
  if (expData?.isPackagesProvided === true) {
    if (expData?.packages.length > 0) {
      for (let i = 0; i < expData?.packages.length; i++) {
        if (expData?.packages?.[i]?.status) {
          for (let i = 0; i < arrData.length; i++) {
            if (lowestDiscount) {
              if (
                arrData?.[index]?.status &&
                !isEmpty(arrData?.[index]?.discountedPrice)
              ) {
                minimumAmount =
                  arrData?.[index]?.discountedPrice === 0
                    ? arrData?.[index]?.discountedPrice
                    : arrData?.[index]?.price;
              } else {
                minimumAmount =
                  arrData?.[index]?.discountedPrice !== null &&
                  arrData?.[index]?.discountedPrice !== undefined
                    ? arrData?.[index]?.discountedPrice
                    : 0;
              }
            } else {
              if (
                arrData?.[i]?.status &&
                !isEmpty(arrData?.[i]?.discountedPrice)
              ) {
                minimumAmount =
                  arrData?.[i]?.discountedPrice === 0
                    ? arrData?.[i]?.discountedPrice
                    : arrData?.[i]?.price;
              } else {
                minimumAmount = arrData?.[i]?.discountedPrice;
              }
            }
          }
        } else {
          minimumAmount = 0;
        }
      }
    }
  } else {
    minimumAmount = 0;
  }
  return minimumAmount;
};

/**
 * GET DISCOUNT GROUP BOOKING
 */
const discountedGroupBook = (data) => {
  let expData = !isEmpty(data) && data;
  if (expData?.isPackagesProvided === true) {
    if (expData?.packages.length > 0) {
      for (let i = 0; i < expData?.packages.length; i++) {
        if (expData?.packages?.[i]?.status) {
          return true;
        }
      }
    }
  } else {
    return false;
  }
};

/**
 * GET RESTAURANT CUISINE
 */
const getRestaurantCuisine = (data) => {
  let expData = !isEmpty(data) && data?.restCuisines;
  return expData;
  // expData.map((cuisine, index) => {
  //   if (index === 0) {
  //     return cuisine;
  //   } else {
  //     return ", " + cuisine;
  //   }
  // });
};

/**
 * GET CLASSIFICATION ACTION
 */
const getClassification = (data, paramData) => {
  let classificationData =
    !isEmpty(data?.classification) &&
    data?.classification.map((data, index) => {
      if (data?.title === undefined) {
        return "";
      } else {
        if (index === 0) {
          return data?.title;
        } else {
          return `${paramData === "new" ? " |" : ","} ` + data?.title;
        }
      }
    });
  let ageDisplay = data?.ageRestriction ? data?.minAge + ` | yrs +` : "";
  return classificationData ? classificationData : "" + ageDisplay;
};

/**
 * GET EXPIRY TIME
 */
const getExperimentTime = (data, name) => {
  // "12 PM-5 PM"
  let todayDays = moment().format("dddd");
  let todayDate = moment().format("DD-MM-YYYY");
  let formData = {};
  if (data?.days.length > 0) {
    for (let i = 0; i <= data?.days.length; i++) {
      if (data?.days?.[i] === todayDays) {
        let getPerticularData = data[data?.days?.[i]];
        for (let l = 0; l <= getPerticularData?.multipleHours.length; l++) {
          if (getPerticularData?.multipleHours?.[l]?.open) {
            formData = getPerticularData?.multipleHours?.[l];
          }
        }
      } else if (data?.days?.[i] === todayDate) {
        let getPerticularDataTwo = data[data?.days?.[i]];
        for (let l = 0; l <= getPerticularDataTwo?.multipleHours.length; l++) {
          if (getPerticularDataTwo?.multipleHours?.[l]?.open) {
            formData = getPerticularDataTwo?.multipleHours?.[l];
          }
        }
      }
    }
  } else {
    return "N/A";
  }
  return (
    moment(formData?.openingTime).format("hh A") +
    " - " +
    moment(formData?.closingTime).format("hh A")
  );
};

/**
 * GET TOTAL AMOUNT
 */
const getTotalAmount = (count, price) => {
  let totalPrice = price * count;
  return totalPrice;
};

/**
 * GET MINIMUM DATE COUNT
 */
const getMinimumDate = (data) => {
  let startDate = moment(data.startDate).format("YYYY-MM-DD");
  let endDate = moment(data.endDate).format("YYYY-MM-DD");
  let todayDate = moment().format("YYYY-MM-DD");
  let a = moment(todayDate);
  let b = moment(startDate);
  let c = moment(endDate);

  let diff = a.diff(b, "days");

  if (todayDate === endDate) {
    return 0;
  } else if (startDate === endDate) {
    return diff;
  } else if (diff >= 0) {
    return 0;
  }
};

/**
 * GET MAXIMUM DATE COUNT
 */
const getMaximumDate = (data) => {
  let startDate = moment(data.startDate).format("YYYY-MM-DD");
  let endDate = moment(data.endDate).format("YYYY-MM-DD");
  let todayDate = moment().format("YYYY-MM-DD");
  let a = moment(endDate);
  let b = moment(todayDate);
  let c = moment(startDate);

  let diff = a.diff(b, "days");
  let diffTwo = c.diff(b, "days");

  if (todayDate === endDate) {
    return todayDate;
  } else if (startDate === endDate) {
    return diffTwo;
  } else if (diff >= 0) {
    return diff;
  } else if (diff < 0) {
    return -1;
  }
};

/**
 * GET ACTIVITY TIMING
 */
const getActivityTimings = (data) => {
  let todayDateFormat = moment().format("dddd");
  let todayDateFormatNum = moment().format("DD-MM-YYYY");
  let expStartDate = moment(data?.startDate).format("DD-MM-YYYY");
  // console.log("🚀 ~ getActivityTimings ~ expStartDate:", todayDateFormat);
  let expEndDate = moment(data?.endDate).format("DD-MM-YYYY");
  let timingData = data?.timings;
  let formData = timingData?.[todayDateFormat];
  let formDataTwo = timingData?.[todayDateFormatNum];

  let formDataThree = {};
  formDataThree = timingData?.[todayDateFormat] || {};
  let foundDateInRange = moment(todayDateFormatNum).isBetween(
    data?.startDate,
    data?.endDate
  );
  if (data.expType === "CURATED" && expStartDate === expEndDate) {
    if (timingData?.days?.includes(expStartDate && expStartDate)) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    }
  } else if (data.expType === "CURATED" && expStartDate <= todayDateFormatNum) {
    if (foundDateInRange) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    } else {
      if (timingData?.days?.includes(expStartDate && expStartDate)) {
        formDataThree.days = expStartDate && expStartDate;
        return formDataThree;
      } else {
        let formDataFour = {
          days: expStartDate,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: "N/A",
              closingTime: "N/A",
              description: "N/A",
            },
          ],
        };
        return formDataFour;
      }
    }
  } else if (data.expType === "CURATED" && todayDateFormatNum <= expEndDate) {
    if (foundDateInRange) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    } else {
      // if (timingData?.days?.includes(todayDateFormat && todayDateFormat)) {
      //   formData.days = todayDateFormat && todayDateFormat;
      //   return formData;
      // } else
      if (timingData?.days?.includes(expStartDate)) {
        formDataThree.days = expStartDate;
        return formDataThree;
      } else if (timingData?.days?.includes(todayDateFormatNum)) {
        formDataTwo.days = todayDateFormatNum;
        return formDataTwo;
      } else {
        let formDataFour = {
          days: todayDateFormatNum || expStartDate,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: "N/A",
              closingTime: "N/A",
              description: "N/A",
            },
          ],
        };
        return formDataFour;
      }
    }
  } else {
    if (foundDateInRange) {
      return {
        ...formDataThree,
        days: expStartDate,
      };
    } else {
      if (timingData?.days?.includes(todayDateFormat && todayDateFormat)) {
        formData.days = todayDateFormat && todayDateFormat;
        return formData;
      } else {
        let formDataFour = {
          days: todayDateFormat,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: "N/A",
              closingTime: "N/A",
              description: "N/A",
            },
          ],
        };
        return formDataFour;
      }
      // else if (
      //   timingData?.days?.includes(todayDateFormatNum && todayDateFormatNum)
      // ) {
      //   formDataTwo.days = todayDateFormatNum && todayDateFormatNum;
      //   return formDataTwo;
      // }
    }
  }
};

const getMultipleDayActivityTimings = (data, selectedDate) => {
  let todayDateFormat = moment(selectedDate).format("dddd");
  let todayDateFormatNum = moment(selectedDate).format("DD-MM-YYYY");
  let timingData = data;
  let formData = timingData?.[todayDateFormat];
  let formDataTwo = timingData?.[todayDateFormatNum];
  if (timingData?.days?.includes(todayDateFormat && todayDateFormat)) {
    formData.days = todayDateFormat && todayDateFormat;
    return formData;
  } else if (
    timingData?.days?.includes(todayDateFormatNum && todayDateFormatNum)
  ) {
    formDataTwo.days = todayDateFormatNum && todayDateFormatNum;
    return formDataTwo;
  } else {
    let formDataThree = {
      days: todayDateFormatNum,
      open: false,
      multipleHours: [
        {
          open: true,
          openingTime: "N/A",
          closingTime: "N/A",
          description: "N/A",
        },
      ],
    };
    return formDataThree;
  }
};

const getSeatingPreference = (data) => {
  let setingData =
    !isEmpty(data) &&
    data.map((seatData, index) => {
      if (seatData === undefined) {
        return "";
      } else {
        if (index === 0) {
          return seatData;
        } else {
          return `, ${seatData}`;
        }
      }
    });
  return setingData;
};

const calculatePaxAndAdultCount = (data) => {
  let expObject = data;
  let formData = {};
  if (expObject?.package?.name === "" && expObject?.package?.count === 0) {
    formData.totalPartySize = +expObject?.adultCount + +expObject?.kidsCount;
    formData.adultCount = +expObject?.adultCount;
    formData.kidsCount = +expObject?.kidsCount;
    formData.paxDetails = `${
      expObject?.adultCount + " Adults, " + expObject?.kidsCount + " Kids"
    }`;
  } else {
    let partyAdult = +expObject?.package?.adults;
    // +expObject?.package?.count *
    // +expObject?.package?.count *
    let partyKids = +expObject?.package?.kids;
    formData.totalPartySize =
      partyAdult + partyKids + +expObject?.adultCount + +expObject?.kidsCount;
    formData.adultCount = +expObject?.adultCount;
    formData.kidsCount = +expObject?.kidsCount;
    formData.pkgWithAdultCount = partyAdult + +expObject?.adultCount;
    formData.pkgWithKidsCount = partyKids + +expObject?.kidsCount;
    formData.paxDetails = `${
      expObject?.package?.count +
      " " +
      expObject?.package?.name +
      ", " +
      expObject?.adultCount +
      " Adults, " +
      expObject?.kidsCount +
      " Kids"
    }`;
  }
  return formData;
};

const getExperienceTaxes = (amount, exp) => {
  let taxData = exp.taxes || [];
  let formData = {
    taxes: {},
    taxesTotal: 0,
    surCharges: {},
    surChargesTotal: 0,
  };
  for (let i = 0; i < taxData.length; i++) {
    if (taxData[i].taxType === "TAX") {
      if (taxData[i].type === "PERCENTAGE") {
        formData["taxes"][taxData[i].code] =
          (amount * parseFloat(taxData[i].value)) / 100;
        formData["taxesTotal"] += (amount * parseFloat(taxData[i].value)) / 100;
      } else {
        formData["taxes"][taxData[i].code] = parseFloat(taxData[i].value);
        formData["taxesTotal"] += parseFloat(taxData[i].value);
      }
    } else {
      if (taxData[i].type === "PERCENTAGE") {
        formData["surCharges"][taxData[i].code] =
          (amount * parseFloat(taxData[i].value)) / 100;
        formData["surChargesTotal"] +=
          (amount * parseFloat(taxData[i].value)) / 100;
      } else {
        formData["surCharges"][taxData[i].code] = parseFloat(taxData[i].value);
        formData["surChargesTotal"] += parseFloat(taxData[i].value);
      }
    }
  }
  return formData;
};

export {
  activeButtonCheck,
  getMinimumAmount,
  getDiscountedAmount,
  discountedGroupBook,
  getRestaurantCuisine,
  getClassification,
  getExperimentTime,
  getTotalAmount,
  getMinimumDate,
  getMaximumDate,
  getActivityTimings,
  getMultipleDayActivityTimings,
  getSeatingPreference,
  calculatePaxAndAdultCount,
  getExperienceTaxes,
};
