import { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Loader from "../../components/loader";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store/slices/userSlice";

export default function ProtectedLayer() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const { handleRequest } = useApi();
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  //console.log("userDetails", userDetails);

  useEffect(() => {
    let storedUserDetails;
    try {
      storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    } catch (error) {
      console.error("Failed to parse user details from localStorage", error);
      storedUserDetails = null;
    }
    console.log("storedUserDetails", storedUserDetails);

    if (!userDetails && storedUserDetails) {
      dispatch(setUser(storedUserDetails));
    }

    if (!storedUserDetails) {
      // navigate('/');
      window.location.replace("/");
      setLoader(false);
    } else {
      (async () => {
        await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
          headers: { Authorization: storedUserDetails.accessToken },
          successMessage: "Successfully validated the user authentication",
          errorMessage: "User authentication failed",
          onSuccess: () => {
            setLoader(false);
          },
          onError: () => {
            localStorage.removeItem("userDetails");
            //localStorage.removeItem("userLocation");
            // navigate("/");
            window.location.replace("/");
            setLoader(false);
          },
        });
      })();
    }
  }, [userDetails, handleRequest]);

  return loader ? (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <Loader />
    </div>
  ) : (
    <Outlet />
  );
}
