import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { NOTAVAILABLEIMAGE } from "../../common/enums/GlobalEnums";
import {
  checkRestaurantClosedOrOpen,
  extractTimingData,
} from "../ONDC/ondcHelperFuction.ts";
import moment from "moment";

const OndcRestaurantCards = ({
  restaurantInfo,
  onPressCardClick,
  favType,
  onClickFavoriteRestaurant,
}) => {
  return (
    <button
      type="button"
      title={restaurantInfo?.descriptor?.name}
      aria-label={restaurantInfo?.descriptor?.name}
      className="w-full bg-white border rounded-3xl relative py-2 shadow-md mb-4 transform active:scale-[.95] transition-transform"
      onClick={onPressCardClick}
    >
      {/*** Image with restaurant name ***/}
      <div className="flex items-center justify-betweens relative min-h-24">
        <div className="w-1/3 relative flex items-center justify-center px-1">
          <div className="w-24 h-24 rounded-full bg-white relative flex items-center justify-center">
            <Image
              src={
                restaurantInfo?.descriptor?.images?.length > 0
                  ? restaurantInfo?.descriptor?.images[0]
                  : NOTAVAILABLEIMAGE
              }
              alt={restaurantInfo?.descriptor?.name}
              title={restaurantInfo?.descriptor?.name}
              aria-label={restaurantInfo?.descriptor?.name}
              className="w-20 h-20 border-[0.6px] rounded-full shadow-md"
            />
            <button
              title="Favorite Restaurant"
              aria-label="Favorite Restaurant"
              className="bg-white absolute top-[-5px] right-[-5px] w-6 h-6 rounded-full shadow-md border-[0.6px] p-[5px] justify-center items-center align-middle transform active:scale-[.95] transition-transform"
              onClick={(e) => {
                e.stopPropagation();
                onClickFavoriteRestaurant(restaurantInfo?.id);
              }}
            >
              <Image
                src={
                  favType
                    ? require("../../assets/images/IconsandImages/heartcoloured.png")
                    : require("../../assets/images/IconsandImages/heart.png")
                }
                alt="heartIcon"
                className="w-full h-auto"
              />
            </button>
          </div>
        </div>
        <div className="w-2/3 text-left px-2 py-2">
          <div className="f20-700 whitespace-nowrap overflow-hidden text-ellipsis w-11/12">
            {restaurantInfo?.descriptor?.name || "N/A"}
          </div>
          <div className="text-xs font-Mulish font-medium whitespace-nowrap overflow-hidden text-ellipsis">
            {restaurantInfo?.descriptor?.long_desc || ""}
          </div>
          <div className="flex items-center justify-start my-2">
            {/* {restaurant?.vegOnly ? (
                <>
                  <Image
                    src={require("../../assets/images/IconsandImages/vegexp.png")}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "5px",
                    }}
                  />
                  <span style={{ fontSize: "13px", marginRight: "10px" }}>
                    Veg
                  </span>
                </>
              ) : ( */}
            <Image
              src={require("../../assets/images/IconsandImages/vegexp.png")}
              className="w-4 h-4 mr-1"
              alt="Veg"
              title="Veg Icon"
            />
            <span style={{ fontSize: "13px", marginRight: "10px" }}>Veg</span>
            <Image
              src={require("../../assets/images/IconsandImages/nonvegexp.png")}
              className="w-4 h-4 mr-1"
              alt="Non-Veg"
              title="Non-Veg Icon"
            />
            <span style={{ fontSize: "13px" }}> Non-Veg</span>
            {/* )} */}
          </div>
          <div className="flex items-center justify-start">
            <p className="text-sm font-Mulish font-normal">
              Time:
              <span className="font-bold">
                {moment(
                  extractTimingData(
                    restaurantInfo?.tags,
                    "timing",
                    "time_from"
                  ),
                  "HHmm"
                ).format("HH:mmA")}{" "}
                -{" "}
                {moment(
                  extractTimingData(restaurantInfo?.tags, "timing", "time_to"),
                  "HHmm"
                ).format("HH:mmA")}
              </span>
            </p>
          </div>
        </div>
      </div>
      {/*** Rating, km, Open and Closed Section ***/}
      <Row className="py-2 justify-content-between f14-500 px-3">
        <Col xs={4} className="flex items-center justify-center">
          <Image
            src={require("../../assets/images/IconsandImages/starExp.png")}
            alt="star"
            style={{ width: "18px", height: "18px", marginRight: "5px" }}
          />
          {"0"}
        </Col>
        <Col xs={4} className="flex items-center justify-center">
          <Image
            src={require("../../assets/images/IconsandImages/distanceexp.png")}
            alt="distanceIcon"
            style={{ width: "18px", height: "18px", marginRight: "5px" }}
          />
          {
            //   restaurant?.userDistance
            //     ? `${(restaurant?.userDistance / 1000).toFixed(1)} Kms`
            //     :
            "0.0 Kms"
          }
        </Col>
        <Col xs={4} className="flex items-center justify-center">
          <Image
            src={require("../../assets/images/IconsandImages/clockexp.png")}
            alt="clockIcon"
            style={{ width: "18px", height: "18px", marginRight: "5px" }}
          />
          <p
            className={`text-base font-Mulish font-bold mb-0 ${
              checkRestaurantClosedOrOpen(restaurantInfo?.tags)
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {checkRestaurantClosedOrOpen(restaurantInfo?.tags)
              ? "Open now"
              : "Closed"}
          </p>
        </Col>
      </Row>
      <div className="absolute top-3 right-3">
        <Image
          src={require("../../assets/images/IconsandImages/surface1.png")}
          alt="verification"
          className="w-4 h-4 object-contain"
        />
      </div>
    </button>
  );
};

OndcRestaurantCards.propTypes = {
  onPressCardClick: PropTypes.func,
  restaurantInfo: PropTypes.object,
  favType: PropTypes.bool,
  onClickFavoriteRestaurant: PropTypes.func,
};

export default OndcRestaurantCards;

// const data = [
//   {
//     context: {
//       city: "std:06755",
//       country: "IND",
//     },
//     message: {
//       catalog: {
//         ["bpp/fulfillments"]: [
//           {
//             id: "0",
//             type: "Delivery",
//           },
//           {
//             id: "1",
//             type: "Self-Pickup",
//           },
//         ],
//         ["bpp/descriptor"]: {
//           name: "ONDC Pramaan Seller Services one",
//           short_desc: "ONDC Pramaan Seller one",
//           long_desc: "ONDC Pramaan Seller one - One Stop Shop",
//         },
//         ["bpp/providers"]: [
//           {
//             id: "pramaan_provider_1",
//             descriptor: {
//               name: "Pramaan Store 1",
//               short_desc: "Pramaan Store 1",
//               long_desc: "Pramaan Store 1",
//             },
//           },
//           {
//             id: "pramaan_provider_2",
//             descriptor: {
//               name: "Pramaan Store 2",
//               short_desc: "Pramaan Store 2",
//               long_desc: "Pramaan Store 2",
//             },
//           },
//         ],
//       },
//     },
//   },
//   {
//     context: {
//       city: "std:06755",
//       country: "IND",
//     },
//     message: {
//       catalog: {
//         ["bpp/fulfillments"]: [
//           {
//             id: "0",
//             type: "Delivery",
//           },
//           {
//             id: "1",
//             type: "Self-Pickup",
//           },
//         ],
//         ["bpp/descriptor"]: {
//           name: "ONDC Pramaan Seller Services Two",
//           short_desc: "ONDC Pramaan Seller Two",
//           long_desc: "ONDC Pramaan Seller Two - One Stop Shop",
//         },
//         ["bpp/providers"]: [
//           {
//             id: "pramaan_provider_3",
//             descriptor: {
//               name: "Pramaan Store 3",
//               short_desc: "Pramaan Store 3",
//               long_desc: "Pramaan Store 3",
//             },
//           },
//           {
//             id: "pramaan_provider_3",
//             descriptor: {
//               name: "Pramaan Store 3",
//               short_desc: "Pramaan Store 3",
//               long_desc: "Pramaan Store 3",
//             },
//           },
//         ],
//       },
//     },
//   },
// ];
