import React, { useEffect } from "react";
import {
  Stack,
  Button,
  styled,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import RippleButton from "../../../../components/buttons/RippleButton";
import { Col, Image, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import propTypes from "prop-types";
import { NOTAVAILABLEIMAGE } from "../../../../common/enums/GlobalEnums";
import { Close } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { extractTimingData } from "../../ondcHelperFuction.ts";
import { isEmpty } from "../../../../common/utility/helperFunc";
import AddToCartOndcButton from "./AddToCartOndcButton";

const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  backgroundColor: "#EE3A23",
  "&:hover": {
    color: "#EE3A23",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EE3A23",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#EE3A23",
  border: "1px solid #EE3A23",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#EE3A23",
    border: "1px solid #FFFFFF",
  },
}));
const OndcCustomizationModal = (props) => {
  const {
    item,
    quantity,
    storedItems,
    setStoredItems,
    orderSelected,
    restData,
    setPriceAlertModal,
    disabledButton,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log("item===>52", item);
  // console.log("item===>54", restData);

  return (
    <React.Fragment>
      <div>
        <RippleButton
          btnText="Add"
          minWidth={"64px"}
          onClickAction={handleOpen}
          btnDisabled={disabledButton}
        />
        {item?.has_customization && (
          <p className="text-sm font-Mulish font-medium mb-0 text-green-500 text-center">
            Customize
          </p>
        )}
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        className="food-menu-combo-modal"
      >
        <Modal.Header className={"border"}>
          <p className="mb-0 text-xl font-Mulish font-bold">Customize</p>
          <button title="closed" onClick={handleClose}>
            <Close fontSize="medium" />
          </button>
        </Modal.Header>
        <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
          <Row className="py-2">
            <Col>
              <p className="text-lg font-Mulish font-medium mb-0">
                {item?.descriptor?.name}
              </p>
            </Col>
            <Col className="text-end">
              <p className="text-lg font-Mulish font-bold mb-0">
                ₹ {extractTimingData(item?.price?.tags, "range", "lower")} - ₹{" "}
                {extractTimingData(item?.price?.tags, "range", "upper")}
              </p>
            </Col>
          </Row>
          {!isEmpty(restData) &&
            !isEmpty(restData?.customGroups) &&
            restData?.customGroups?.length > 0 &&
            restData?.customGroups.map((groupData) => {
              return (
                <Accordion
                  key={groupData?.id}
                  defaultExpanded={true}
                  id={groupData?.descriptor?.name}
                  className="mb-3"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1aCustomize-content"
                    id="panel1aCustomize-header"
                    className="m-0"
                  >
                    <Typography
                      component="span"
                      className="text-lg font-Mulish font-semibold"
                    >
                      {groupData?.descriptor?.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!isEmpty(groupData) &&
                      !isEmpty(groupData?.items) &&
                      groupData?.items?.length > 0 &&
                      groupData?.items.map((items, index) => {
                        return (
                          <Row key={items?.id} className="mb-2">
                            <Col
                              xs={10}
                              className="flex items-center justify-start"
                            >
                              <FormControlLabel
                                className="mr-3"
                                value={items?.price?.value}
                                control={
                                  <Checkbox
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "red",
                                      },
                                    }}
                                    className="py-0"
                                  />
                                }
                                checked={
                                  extractTimingData(
                                    items?.tags,
                                    "parent",
                                    "default"
                                  ) === "yes"
                                    ? true
                                    : false
                                }
                                onChange={
                                  () => {}
                                  // onChangeSize(index)
                                }
                              />
                              <Image
                                src={require(`../../../../assets/images/IconsandImages/${
                                  extractTimingData(
                                    items?.tags,
                                    "veg_nonveg",
                                    "veg"
                                  ) === "yes"
                                    ? "vegindicator.png"
                                    : "nonveg_indicator.png"
                                }`)}
                                alt="Veg-Nog Veg"
                                className="h-3 w-3 object-fill mr-2"
                              />
                              <p className="text-sm font-Mulish font-medium mb-0">
                                {items?.descriptor?.name}
                              </p>
                            </Col>
                            <Col
                              xs={2}
                              className="flex items-center justify-end"
                            >
                              <p className="text-sm font-Mulish font-bold mb-0">
                                ₹ {items?.price?.value}
                              </p>
                            </Col>
                          </Row>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end">
            <Stack
              spacing={2}
              direction="row"
              className="justify-end items-end"
            >
              {/* {!itemExits && ( */}
              {/* <OutlinedButton
                variant="outlined"
                style={{ width: "50%" }}
                onClick={handleClose}
              >
                Cancel
              </OutlinedButton>
              {/* )} *s
              <ColorButton
                variant="contained"
                style={{ width: "50%" }}
                onClick={handleClose}
              >
                Submit
              </ColorButton> */}
              <AddToCartOndcButton item={item} restData={restData} />
            </Stack>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

OndcCustomizationModal.propTypes = {
  item: propTypes.array || propTypes.object || propTypes.string,
  disabledButton: propTypes.bool.isRequired,
  storedItems: propTypes.array || propTypes.object || propTypes.string,
  setStoredItems: propTypes.array || propTypes.object || propTypes.string,
  orderSelected: propTypes.array || propTypes.object || propTypes.string,
  restData: propTypes.array || propTypes.object || propTypes.string,
  setPriceAlertModal: propTypes.array || propTypes.object || propTypes.string,
};

export default OndcCustomizationModal;
