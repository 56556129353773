import React from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function CartBar({
  intialOrderCall,
  setshowOtpModal,
  setdineInSkip,
  summary,
  from,
  viewCartInfo,
}) {
  const { ID } = useParams();

  const navigate = useNavigate();

  const { state } = useLocation();
  //onsole.log("🚀 ~ CartBar ~ state:", state);

  const cartData = JSON.parse(sessionStorage.getItem("cartItems")) || [];

  const orderType = sessionStorage.getItem("cartOrderType");
  const skipStatus = sessionStorage.getItem("DineInSkip");
  const CurSideSkipStatus = sessionStorage.getItem("CurbSideSkip");

  const path = window.location.pathname;

  // const hasAccessToken = sessionStorage.getItem('accessToken');
  const hasAccessToken = useSelector(
    (state) => state.user.userDetails.accessToken
  );

  const totalSum = cartData.reduce((sum, currentObject) => {
    return sum + currentObject.finalPrice * currentObject.quantity;
  }, 0);
  const totalItems = cartData.reduce((sum, currentObject) => {
    return sum + currentObject.quantity;
  }, 0);

  const viewcart = () => {
    const isCartPage = path.includes("cartpage");
    const isOndcCartPage = path.includes("ondccart");
    if (isCartPage) {
      if (hasAccessToken) {
        if (orderType === "dine_in" && skipStatus === "skipped") {
          setdineInSkip(true);
        } else if (
          orderType === "curb_side" &&
          CurSideSkipStatus === "skipped"
        ) {
          navigate(`/restaurant/${ID}/curbside-details`);
        } else if (intialOrderCall) {
          intialOrderCall();
        }
      } else if (setshowOtpModal) {
        setshowOtpModal(true);
      }
    } else if (isOndcCartPage) {
      intialOrderCall();
    } else {
      if (from === "ONDC") {
        navigate(`/ondc/${ID}/ondccart`, {
          state: {
            from: state?.from,
            fromOndc: true,
          },
        });
      } else {
        navigate(`/restaurant/${ID}/food/cartpage`, {
          state: {
            from: state?.from,
          },
        });
      }
    }
  };

  return (
    <div className="cartbar f16-700">
      <div className="d-flex align-items-center my-2">
        <div className="px-1">
          {from === "ONDC" ? viewCartInfo?.quantity : totalItems}
          {from === "ONDC" ? (
            <span className="px-1" style={{ borderRight: "2px solid white" }}>
              {" "}
              {viewCartInfo?.quantity > 1 ? "ITEMS" : "ITEM"}
            </span>
          ) : (
            <span className="px-1" style={{ borderRight: "2px solid white" }}>
              {" "}
              {totalItems > 1 ? "ITEMS" : "ITEM"}
            </span>
          )}
        </div>
        {from === "ONDC" ? (
          <div className="px-1">
            ₹{" "}
            {path.includes("ondccart")
              ? viewCartInfo?.totalAmount || 0
              : viewCartInfo?.totalPrice}
            {path.includes("ondccart") ? (
              <p className="f10-500 m-0">plus Taxes</p>
            ) : null}
          </div>
        ) : (
          <div className="px-1">
            ₹ {path.includes("cartpage") ? summary?.total || 0 : totalSum}
            {path.includes("cartpage") ? (
              <p className="f10-500 m-0">plus Taxes</p>
            ) : null}
          </div>
        )}
      </div>
      <button onClick={viewcart} className="flexCenter cursor-pointer">
        <Image
          src={require("../../assets/images/ordermodalassets/cart_bag.png")}
          width={20}
          alt="logo"
        />
        <div className="mx-2">
          {path.includes("cartpage") || path.includes("ondccart")
            ? "Check Out"
            : "View Cart"}
        </div>
      </button>
    </div>
  );
}

export default CartBar;
