import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const RelatedExperiences = ({
  relatedResponseData,
  toggleFavorite,
  favorites,
}) => {
  const navigate = useNavigate();
  // console.log("RelatedExperiences", relatedResponseData);
  if (!relatedResponseData || relatedResponseData.length === 0)
    return (
      <div
        className="f18-700"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        No restaurants available
      </div>
    );
  return (
    <>
      {relatedResponseData.map((experience) => {
        const isLiked = favorites.includes(experience._id);

        return (
          <div
            key={experience._id}
            style={{ padding: "10px 20px", marginBottom: "40px" }}
          >
            {/* Experience Title */}
            <div
              style={{ fontSize: "28px", fontWeight: "bold", color: "#000" }}
            >
              {experience.restaurantName || "Restaurant Name"}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  marginTop: "-30px",
                }}
              >
                <Image
                  src={
                    isLiked
                      ? require("../../assets/images/IconsandImages/heartcoloured.png")
                      : require("../../assets/images/IconsandImages/heart.png")
                  }
                  alt="Like"
                  style={{ width: "16px", height: "16px" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent navigation event
                    toggleFavorite(experience._id);
                  }}
                />

                <Image
                  className="icon"
                  src={require("../../assets/images/IconsandImages/star 1.png")}
                  alt="Rating"
                  style={{ width: "18px", height: "18px" }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {experience.expReviewed?.toFixed(1) || "N/A"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "12px",
                  marginTop: "-40px",
                }}
              >
                <Button
                  variant="light"
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                    border: "1px solid #ddd",
                  }}
                >
                  <Image
                    src={require("../../assets/images/IconsandImages/call.png")}
                    alt="Call"
                    style={{ width: "18px", height: "18px" }}
                  />
                </Button>
              </div>
            </div>

            {/* Cuisines */}
            <div
              className="f14-400 mt-4"
              style={{
                maxWidth: "45%",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {/* {experience.restCuisines || "Cuisines not available"} */}
              {experience.restCuisines
                ? experience.restCuisines.length > 100
                  ? `${experience.restCuisines.substring(0, 100)}...`
                  : experience.restCuisines
                : "Cuisines not available"}
            </div>

            {/* Address */}
            <div style={{ fontSize: "14px", color: "#999", marginTop: "5px" }}>
              {experience.venueAddress}
            </div>

            {/* Status and Price */}
            <div className="f14-400" style={{ marginTop: "5px" }}>
              <span style={{ color: "#ffa500" }}>Open now</span> 11a.m - 12p.m |
              ₹{experience.adultPrice || "N/A"} for two
            </div>

            {/* Header Icons */}

            {/* Image Section */}
            <Row style={{ marginTop: "20px" }}>
              <Col md={6}>
                {experience.photos?.length > 0 ? (
                  <Image
                    src={experience.photos[0]}
                    alt="Event"
                    style={{
                      width: "100%",
                      height: "280px",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </Col>
              <Col
                md={6}
                className="d-flex flex-column justify-content-between"
              >
                {experience.photos?.length > 1 ? (
                  <>
                    <Image
                      src={experience.photos[1]}
                      alt="Secondary Event"
                      style={{
                        width: "80%",
                        height: "140px",
                        borderRadius: "10px",
                        marginBottom: "10px",
                      }}
                    />
                    {experience.photos.length > 2 && (
                      <Image
                        src={experience.photos[2]}
                        alt="Tertiary Event"
                        style={{
                          width: "90%",
                          height: "140px",
                          borderRadius: "10px",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <p>No additional images available</p>
                )}
              </Col>
            </Row>

            {/* Action Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <Button variant="outline-danger" style={buttonStyle}>
                <Image
                  src={require("../../assets/images/IconsandImages/crossExp.png")}
                  alt="Cancel"
                  style={{ width: "16px", height: "16px" }}
                />
                Cancel
              </Button>

              <Button variant="outline-danger" style={buttonStyle}>
                <Image
                  src={require("../../assets/images/IconsandImages/chatExp.png")}
                  alt="Chat"
                  style={{ width: "16px", height: "16px" }}
                />
                Chat
              </Button>

              <Button variant="outline-danger" style={buttonStyle}>
                <Image
                  src={require("../../assets/images/IconsandImages/helpExp.png")}
                  alt="Help"
                  style={{ width: "16px", height: "16px" }}
                />
                Help
              </Button>
              <Button variant="outline-danger" style={buttonStyle}>
                <Image
                  src={require("../../assets/images/IconsandImages/addItemsExp.png")}
                  alt="Add Items"
                  style={{ width: "16px", height: "16px" }}
                />
                Add Items
              </Button>
              <Button variant="outline-danger" style={buttonStyle}>
                <Image
                  src={require("../../assets/images/IconsandImages/shareExp.png")}
                  alt="Share"
                  style={{ width: "16px", height: "16px" }}
                />
                Share
              </Button>

              <Button
                variant="danger"
                style={{
                  padding: "10px 25px",
                  borderRadius: "10px",
                  backgroundColor: "#EE3A23",
                  border: "none",
                  color: "white",
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "200px",
                }}
                onClick={() =>
                  navigate(
                    `/experience/${experience.restaurantId._id}/${experience._id}/booking`
                  )
                }
              >
                Book Now
              </Button>
            </div>
          </div>
        );
      })}
    </>
  );
};

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "10px 20px",
  borderRadius: "10px",
  border: "1px solid red",
  color: "#fc5a47",
  backgroundColor: "white",
  cursor: "pointer",
};

export default RelatedExperiences;
