import React, { useState, useEffect } from "react";
import RestaurantCard from "../ReusableComponent/restaurantCard";
import ScreenTemplate from "../../components/ScreenTemplate";
import MainSideBar from "../../components/MainsideBar";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import { URL } from "../../common/api/urls";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useAxios } from "../../common/api/hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../../setup/socket/SocketContext";
import {
  clearOndcRestaurants,
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcRestaurants,
  saveOndcViewCartBottomInfo,
} from "../../store/slices/ondcSlice";
import OndcSeachBar from "./Components/ondcSearchBar";
import moment from "moment/moment";
import OndcRestaurantCards from "../ReusableComponent/OndcRestaurantCards";
import { ondcRestaurantSearch, setViewCartInfo } from "./ondcHelperFuction.ts";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../common/utility/helperFunc.js";
import CartBar from "../ReusableComponent/CartBar.js";
import { getItemsAddedToCart } from "./ondcCart.js";

const ALL_CATEGORY_KEYS = {
  RESTAURANT_TYPE: "5f562c9094ac7fdf47ea1d0a",
  FOOD_TYPE: "5f562c9094ac7fdf47ea1d1d",
  LIQUORS: "5f562c9094ac7fdf47ea1d1c",
};

const OndcRestaurantsList = (props) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();

  const {
    coordinates,
    currentLocation,
    setCurrentLocation,
    updateLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const dispatch = useDispatch();

  const userAuth = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );
  const ondcRestaurants = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcRestaurants
  );
  const getOndcSavedOrderInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );
  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );

  const getOndcBottomViewCartInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcViewCartBottomInfo
  );

  const [cancel, ondcRestData, error, loaded, reset, executeOndcRestApi] =
    useAxios();

  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bottomViewCartInfo, setBottomViewCartInfo] = useState({});

  useEffect(() => {
    setRestaurants(ondcRestaurants);
  }, [ondcRestaurants]);

  useEffect(() => {
    if (!isEmpty(getOndcBottomViewCartInfo)) {
      setBottomViewCartInfo(getOndcBottomViewCartInfo);
    }
  }, [getOndcBottomViewCartInfo]);

  const fetchOndcRestaurants = async () => {
    try {
      dispatch(clearOndcRestaurants());
      const currentLatitude = location?.lat;
      const currentLongitude = location?.lng;
      const todayData = moment().toISOString();

      let formData = {
        context: {
          city: "std:06755",
        },
        intent: {
          fulfillment: {
            type: "Delivery",
            end: {
              location: {
                gps: `${currentLatitude}, ${currentLongitude}`,
                address: {
                  area_code: "500082",
                },
              },
            },
          },
          payment: {
            ["@ondc/org/buyer_app_finder_fee_type"]: "percent",
            ["@ondc/org/buyer_app_finder_fee_amount"]: "3",
          },
          tags: [
            {
              code: "catalog_full",
              list: [
                {
                  code: "static_terms",
                  value: "",
                },
                {
                  code: "static_terms_new",
                  value:
                    "https://github.com/ONDC-Official/NP-Static-Terms/buyerNP_BNP/1.0/tc.pdf",
                },
                {
                  code: "effective_date",
                  value: todayData,
                },
              ],
            },
          ],
        },
      };

      const headers = {
        "Content-Type": "application/json",
        _id: userAuth.User?._id ? userAuth.User?._id : userAuth?._id,
      };

      await executeOndcRestApi(
        `${URL.GET_ONDC_RESTAURNATS}`,
        "POST",
        formData,
        headers,
        (response) => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log("ONDC-RESTAURANTS-LIST-ERROR", error);
    }
  };

  useEffect(() => {
    fetchOndcRestaurants();
    // !isEmpty(getOndcSavedOrderInfo) &&
    if ( !isEmpty(getOndcSavedCartItems)) {
      dispatch(saveOndcOrderInfo(getOndcSavedOrderInfo));
      dispatch(saveOndcCartItems(getOndcSavedCartItems));
      dispatch(saveOndcViewCartBottomInfo(getOndcBottomViewCartInfo));
    } else {
      dispatch(saveOndcOrderInfo({}));
      dispatch(saveOndcCartItems([]));
      dispatch(saveOndcViewCartBottomInfo({}));
    }
  }, [location]);

  const getUserCartONDC = async () => {
    getItemsAddedToCart(userAuth?._id, userAuth?.accessToken).then((res) => {
      console.log(res);
      dispatch(saveOndcCartItems(res?.data?.items || []));
      dispatch(saveOndcOrderInfo(getOndcSavedOrderInfo));
      dispatch(
        saveOndcViewCartBottomInfo(
          setViewCartInfo(res?.data?.items, getOndcSavedOrderInfo)
        )
      );
    });
  };

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        userAuth.accessToken
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log("Socket Re-Authenticated");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    const ondcSocket = props.socket.service("ondc");
    ondcSocket.on("ondc_on_search", (data) => {
      console.log("ONDC-RESTAURANTS-LIST", data);
      const restaurants = data;
      dispatch(saveOndcRestaurants(restaurants));
    });
    getUserCartONDC();
  }, []);

  const onSearch = (value) => {
    const searchResult = ondcRestaurantSearch(ondcRestaurants, value);
    setRestaurants(searchResult);
  };

  const ondcFavoriteAction = (favData) => {
    console.log("favorite", favData);
  };

  const ondcRestaurantsClick = (wholeRestData, providerData) => {
    const paramsData = {
      restContext: wholeRestData,
      restaurantsInfo: providerData,
    };
    navigate(`/ondc/${providerData?.id}/menu`, { state: paramsData });
  };

  const content = (
    <>
      <OndcSeachBar onChange={onSearch} />
      <div
        className={`d-flex ${isDesktop ? "flex-row" : "flex-column"} ${
          isDesktop ? "" : "justify-content-center align-items-center"
        }`}
        style={{
          gap: "20px",
          marginTop: "15px",
        }}
      >
        <div>
          <div className="f22-700 ml-1 mb-4">Neighboring Restaurants</div>
          {/* <RestaurantCard restaurants={restaurantsToRender} from="ONDC" /> */}
          {isLoading && <div>Loading more restaurants...</div>}
        </div>
      </div>
      <Container fluid className="p-0">
        <Row>
          {restaurants.flatMap((item) =>
            item.message.catalog["bpp/providers"].map((provider) => {
              return (
                <React.Fragment key={provider?.id}>
                  <Col sm={12} md={4} lg={4}>
                    <OndcRestaurantCards
                      restaurantInfo={provider}
                      onPressCardClick={() =>
                        ondcRestaurantsClick(item, provider)
                      }
                      favType={false}
                      onClickFavoriteRestaurant={ondcFavoriteAction}
                    />
                  </Col>
                </React.Fragment>
              );
            })
          )}
        </Row>
      </Container>
      <div className="my-4" style={{ backgroundColor: "#F4F3FE" }}>
        <div
          className="d-flex justify-content-center align-items-center f24-500 "
          style={{ gap: "1px" }}
        >
          <span>With</span>
          <Image
            src={require("../../assets/images/ExperiencePageAssets/love-location.png")}
            alt="Love"
            title="Love"
            width={300}
            style={{ marginLeft: "-75px", marginRight: "-75px" }}
          />
          <span>Amealio</span>
        </div>
      </div>
      <div className="col-12 p-0">
        {/* !isEmpty(getOndcSavedOrderInfo) && */}
        {!isEmpty(getOndcSavedCartItems) &&
          getOndcSavedCartItems?.length > 0 && (
            <CartBar
              // setshowOtpModal={setshowOtpModal}
              from="ONDC"
              viewCartInfo={bottomViewCartInfo}
            />
          )}
      </div>
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
};

const OndcRestaurantsMain = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OndcRestaurantsList {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default OndcRestaurantsMain;
