import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const Cuisine = () => {
  const [cuisines, setCuisines] = useState([]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.cuisine) {
      //console.log("Fetched cuisines from localStorage:", userDetails.cuisine);
      setCuisines(userDetails.cuisine);
    } else {
      console.warn("No cuisines found in localStorage.");
    }
  }, []);

  // Static cuisine data
  const staticCuisines = [
    {
      name: "Indian",
      image: require("../../assets/images/IconsandImages/indian.png"),
    },
    {
      name: "Chinese",
      image: require("../../assets/images/IconsandImages/chinise.png"),
    },
    {
      name: "Italian",
      image: require("../../assets/images/IconsandImages/italian.png"),
    },
    {
      name: "South Indian",
      image: require("../../assets/images/IconsandImages/southindia.jpg"),
    },
    {
      name: "Asian",
      image: require("../../assets/images/IconsandImages/asian.png"),
    },
    {
      name: "Desserts",
      image: require("../../assets/images/IconsandImages/Desserts.png"),
    },
    {
      name: "American",
      image: require("../../assets/images/IconsandImages/american.png"),
    },
  ];

  return (
    <div className="mt-2">
      {/* Title aligned to top-left */}
      <div className="f22-700 ml-1 mb-4">Cuisine You'll Like</div>

      <Row className="g-5 ml-1 justify-content-start">
        {staticCuisines.map((cuisine, index) => (
          <Col key={index} xs="auto" className="text-center">
            <Image
              src={cuisine.image}
              rounded
              fluid
              style={{ width: "90px", height: "90px", objectFit: "cover" }}
            />
            <p className="mt-1 fs-6">{cuisine.name}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Cuisine;
