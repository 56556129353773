import useApi from '../common/api/hooks/useApi';
import {
  setError,
  setLoading,
  setNotifications,
} from '../store/slices/notificationSlice';
import { URL } from '../common/api/urls';

const useNotificationsApi = () => {
  const { handleRequest } = useApi();

  const fetchNotifications = async (userId, dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await handleRequest('get', `${URL.NOTIFICATIONS}/${userId}`);
      dispatch(setNotifications(response));
    } catch (error) {
      dispatch(setError(error.message || 'Failed to fetch notifications.'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  //   const markAsRead = async (notificationIds, dispatch) => {
  //     try {
  //       await handleRequest(
  //         'post',
  //         '/notifications/mark-read',
  //         { ids: notificationIds },
  //         {
  //           successMessage: 'Notifications marked as read!',
  //           errorMessage: 'Failed to mark notifications as read.',
  //         }
  //       );
  //       dispatch(markSpecific(notificationIds));
  //     } catch (error) {
  //       dispatch(
  //         setError(error.message || 'Failed to mark notifications as read.')
  //       );
  //     }
  //   };

  //   const clearAllNotifications = async (userId, dispatch) => {
  //     try {
  //       await handleRequest('post', `/notifications/clear/${userId}`, null, {
  //         successMessage: 'All notifications cleared!',
  //         errorMessage: 'Failed to clear notifications.',
  //       });
  //       dispatch(clearNotifications());
  //     } catch (error) {
  //       dispatch(setError(error.message || 'Failed to clear notifications.'));
  //     }
  //   };

  //   const deleteNotification = async (notificationId, dispatch) => {
  //     try {
  //       await handleRequest('delete', `/notifications/${notificationId}`, null, {
  //         successMessage: 'Notification deleted successfully!',
  //         errorMessage: 'Failed to delete notification.',
  //       });
  //       dispatch(removeNotification(notificationId));
  //     } catch (error) {
  //       dispatch(setError(error.message || 'Failed to delete notification.'));
  //     }
  //   };

  return {
    fetchNotifications,
    // markAsRead,
    // clearAllNotifications,
    // deleteNotification,
  };
};

export default useNotificationsApi;
