import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RippleButton from "../../../../components/buttons/RippleButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { isEmpty } from "../../../../common/utility/helperFunc";
import {
  ondcInitialCartData,
  setViewCartInfo,
} from "../../ondcHelperFuction.ts";
import {
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcViewCartBottomInfo,
} from "../../../../store/slices/ondcSlice.js";
import { useDispatch, useSelector } from "react-redux";
import OndcCustomizationModal from "./OndcCustomizationModal.jsx";
import { addItemsToCartServerCall } from "../../ondcCart.js";

const AddToCartOndcButton = (props) => {
  const { item, restData } = props;
  const dispatch = useDispatch();
  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );
  const ondcOrderInfoStoreData = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );
  const userData = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );

  const [state, setState] = useState({
    quantity: 0,
    alreadyInCartModal: false,
  });
  const { quantity } = state;
  const [initialCartData, setInitialCartData] = useState(ondcInitialCartData());

  useEffect(() => {
    let totalItems = 0;
    if (
      Array.isArray(getOndcSavedCartItems) &&
      getOndcSavedCartItems?.length > 0
    ) {
      totalItems = getOndcSavedCartItems.reduce((total, element) => {
        if (element?.id.includes(item.id)) {
          return total + (element?.quantity?.count || 0);
        }
        return total;
      }, 0);
    }
    setState({
      ...state,
      quantity: totalItems,
    });
  }, []);

  /*****************************
   * @DESC ADD MENU ITEM TO CART
   ****************************/
  const onClickAddToCart = useCallback(
    async (item, quantity) => {
      if (+item?.quantity?.maximum?.count <= 0) {
        console.log("You cannot add more than the item's maximum quantity");
      } else {
        debugger;
        try {
          // const [orderData, cartData] = await Promise.all([
          //   localStorage.getItem("ondcOrderDetails"),
          //   localStorage.getItem("ondcCartDetails"),
          // ]);
          const parsedOrder = ondcOrderInfoStoreData;
          const parsedCart = getOndcSavedCartItems || [];
          let initialCartInfo = initialCartData;
          const singleCartItem = JSON.parse(JSON.stringify(item));
          singleCartItem['quantity'] = {
            count: 1,
          };
          let newCartData = [...parsedCart];
          if (isEmpty(parsedCart) || parsedOrder?.id === restData?.id) {
            setState((prevState) => ({
              ...prevState,
              quantity: 1,
            }));
            newCartData.push(singleCartItem);
            initialCartInfo.context = restData?.context;
            initialCartInfo.message.order.restId = restData?.id;
            initialCartInfo.message.order.fulfillments = restData?.fulfillments;
            initialCartInfo.message.order.tags = restData?.tags;
            initialCartInfo.message.order.provider = {
              ...restData?.descriptor,
              id: restData?.id,
              locations: restData?.locations,
            };
            initialCartInfo.message.order.items = newCartData;
            onClickContinue(newCartData, initialCartData);
          } else {
            setState((prevState) => ({
              ...prevState,
              alreadyInCartModal: true,
            }));
            return;
          }
        } catch (error) {
          console.error("Error updating cart:", error);
        }
      }
    },
    [dispatch, restData, ondcOrderInfoStoreData, getOndcSavedCartItems]
  );

  /**
   * ITEM INCREMENT FUNC
   */
  const onClickAddMoreItem = useCallback(
    async (onItem, itemQuantity) => {
      if (+onItem?.quantity?.maximum?.count <= itemQuantity) {
        // showError("You cannot add more than the item's maximum quantity.");
        console.log("EnterThis===>90");
      } else {
        try {
          // const [orderData, getOndcCartData] = await Promise.all([
          //   localStorage.getItem("ondcOrderDetails"),
          //   localStorage.getItem("ondcCartDetails"),
          // ]);
          const initialCartData = ondcOrderInfoStoreData;
          const parsedCart = JSON.parse(JSON.stringify(getOndcSavedCartItems)) || [];
          let itemInCartIndex = parsedCart.findIndex(
            (item) => item?.id === onItem?.id
          );

          if (itemInCartIndex !== -1) {
            let itemInCart = parsedCart[itemInCartIndex];

            if (
              itemInCart?.quantity?.count >= onItem?.quantity?.maximum?.count
            ) {
              console.log("EnterThis===>106");
            } else {
              // Update the quantity
              itemInCart = {
                ...itemInCart,
                quantity: {
                  ...itemInCart.quantity,
                  count: (itemInCart?.quantity?.count || 0) + 1,
                },
              };

              // Replace the updated item in parsedCart
              parsedCart[itemInCartIndex] = itemInCart;

              // Update state with the new quantity
              setState((prevState) => ({
                ...prevState,
                quantity: itemInCart.quantity.count,
              }));

              // Dispatch and update localStorage
              // dispatch(saveOndcCartItems(parsedCart));
              // localStorage.setItem("ondcCartDetails", JSON.stringify(parsedCart));

              onClickContinue(parsedCart, initialCartData);
            }
          }
        } catch (error) {
          console.error("Error updating cart:", error);
        }
      }
    },
    [dispatch, ondcOrderInfoStoreData, getOndcSavedCartItems]
  );

  /**
   * ITEM DECREMENT FUNC
   */
  const onClickRemoveItem = useCallback(
    async (onItem, quantity) => {
      try {
        // const [orderData, getOndcCartData] = await Promise.all([
        //   localStorage.getItem("ondcOrderDetails"),
        //   localStorage.getItem("ondcCartDetails"),
        // ]);
        const initialCartData = ondcOrderInfoStoreData;
        const parsedCart = JSON.parse(JSON.stringify(getOndcSavedCartItems)) || [];
        const lastIndex = parsedCart.findIndex(
          (item) => item.id === onItem?.id
        );
        if (lastIndex === -1) return;
        const item = parsedCart[lastIndex];
        const currentCount = item?.quantity?.count || 0;
        if (currentCount <= 1) {
          parsedCart.splice(lastIndex, 1);
        } else {
          item.quantity.count -= 1;
        }
        const totalItems = parsedCart.reduce((total, element) => {
          if (element.id === onItem?.id) {
            total += element?.quantity?.count || 0;
          }
          return total;
        }, 0);
        setState((prevState) => ({
          ...prevState,
          quantity: totalItems,
        }));
        // dispatch(saveOndcCartItems(parsedCart));
        // localStorage.setItem("ondcCartDetails", JSON.stringify(parsedCart));
        onClickContinue(parsedCart, initialCartData);
      } catch (error) {
        console.error("Error updating cart:", error);
      }
    },
    [dispatch, ondcOrderInfoStoreData, getOndcSavedCartItems]
  );

  /*****************************
   * @DESC CONTINUE MENU ITEM CART
   ****************************/
  const onClickContinue = async (exitingCartDataArr, initialCartOrderInfo) => {
    let orderInfoData = JSON.parse(JSON.stringify(initialCartOrderInfo));
    let cartItems = exitingCartDataArr;
    /*** VIEW CART ***/
    let finalData = setViewCartInfo(cartItems, orderInfoData);
    orderInfoData.message.order.base_amount = +finalData?.totalPrice;
    orderInfoData.message.order.total_amount = +finalData?.totalAmount;
    dispatch(saveOndcOrderInfo(orderInfoData));
    dispatch(saveOndcCartItems(cartItems));

    // API Call to add the items to cart in the backend
    const itemsAddedCheck = await addItemsToCartServerCall(
      userData?.accessToken,
      {
        items: cartItems,
        transaction_id: orderInfoData?.context?.transaction_id,
      }
    );

    if (itemsAddedCheck) {
      // await Promise.all([
      //   localStorage.setItem("ondcOrderDetails", JSON.stringify(orderInfoData)),
      //   localStorage.setItem("ondcCartDetails", JSON.stringify(cartItems)),
      // ]);
      // let getOndcCartData = localStorage.getItem("ondcCartDetails");
      // let parsedItems = (getOndcCartData);
      setViewCartInfoAction(cartItems, orderInfoData);
    }
  };

  const setViewCartInfoAction = (parsedItems, orderInfoData) => {
    let finalData = setViewCartInfo(parsedItems, orderInfoData);
    // localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
  };

  return (
    <div className="flex items-center justify-center">
      {!isEmpty(quantity) && quantity > 0 ? (
        <div className="inline-flex rounded-md bg-white" role="group">
          <RippleButton
            minWidth={"30px"}
            btnText={<RemoveIcon fontSize="20px" />}
            borderRight={"none"}
            borderTopRightRadius={"0px"}
            borderBottomRightRadius={"0px"}
            borderTopLeftRadius={"10px"}
            borderBottomLeftRadius={"10px"}
            onClickAction={() =>
              onClickRemoveItem(
                item,
                quantity,
                ondcOrderInfoStoreData,
                getOndcSavedCartItems
              )
            }
          />
          <RippleButton
            minWidth={"20px"}
            btnText={quantity}
            hoverBgColor={"#FFFFFF"}
            hoverColor={"#1976d2"}
            borderRight={"0px solid #C0C0C0"}
            borderLeft={"0px solid #C0C0C0"}
            borderTopLeftRadius={"0px"}
            borderTopRightRadius={"0px"}
            borderBottomLeftRadius={"0px"}
            borderBottomRightRadius={"0px"}
          />
          <RippleButton
            minWidth={"30px"}
            btnText={<AddIcon fontSize="20px" />}
            borderLeft={"none"}
            borderTopLeftRadius={"0px"}
            borderBottomLeftRadius={"0px"}
            borderTopRightRadius={"10px"}
            borderBottomRightRadius={"10px"}
            onClickAction={() =>
              onClickAddMoreItem(
                item,
                quantity,
                ondcOrderInfoStoreData,
                getOndcSavedCartItems
              )
            }
          />
        </div>
      ) : (
        <RippleButton
          btnText="Add"
          minWidth={"64px"}
          onClickAction={() =>
            onClickAddToCart(
              item,
              quantity,
              ondcOrderInfoStoreData,
              getOndcSavedCartItems
            )
          }
        />
      )}
    </div>
  );
};

AddToCartOndcButton.propTypes = {
  item: PropTypes.object.isRequired,
  restData: PropTypes.object.isRequired,
};

export default memo(AddToCartOndcButton);
