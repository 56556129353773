import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const ColorButton = styled(Button)(
  ({
    minWidth,
    hoverBgColor,
    hoverColor,
    borderTop,
    borderLeft,
    borderRight,
    borderBottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  }) => ({
    color: "#1976d2",
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    fontSize: 14,
    padding: "2px 4px",
    lineHeight: 1.5,
    minWidth: minWidth || "30px",
    height: "auto",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    borderTop: borderTop || "1px solid #1976d2",
    borderLeft: borderLeft || "1px solid #1976d2",
    borderRight: borderRight || "1px solid #1976d2",
    borderBottom: borderBottom || "1px solid #1976d2",
    borderTopLeftRadius: borderTopLeftRadius,
    borderTopRightRadius: borderTopRightRadius,
    borderBottomLeftRadius: borderBottomLeftRadius,
    borderBottomRightRadius: borderBottomRightRadius,
    "&:hover": {
      backgroundColor: hoverBgColor || "#1976d2",
      color: hoverColor || "#FFFFFF",
      borderLeft: "none",
      borderRight: "none",
      //   border: hoverBorder || "1px solid #1976d2",
      //   borderRadius: borderHoverRadius,
    },
  })
);

const CustomizedButtons = (props) => {
  const {
    btnText,
    btnDisabled,
    minWidth,
    hoverBgColor,
    hoverColor,
    borderTop,
    borderLeft,
    borderRight,
    borderBottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    onClickAction,
  } = props;
  return (
    <ColorButton
      variant="outlined"
      disabled={btnDisabled}
      minWidth={minWidth}
      hoverBgColor={hoverBgColor}
      hoverColor={hoverColor}
      borderTop={borderTop}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderTopLeftRadius={borderTopLeftRadius}
      borderTopRightRadius={borderTopRightRadius}
      borderBottomLeftRadius={borderBottomLeftRadius}
      borderBottomRightRadius={borderBottomRightRadius}
      onClick={onClickAction}
    >
      {btnText}
    </ColorButton>
  );
};

CustomizedButtons.propTypes = {
  btnDisabled: PropTypes.bool.isRequired,
  btnText: PropTypes.string.isRequired,
  minWidth: PropTypes.string,
  hoverBgColor: PropTypes.string,
  hoverColor: PropTypes.string,
  borderTop: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
  borderBottom: PropTypes.string,
  borderTopLeftRadius: PropTypes.string,
  borderTopRightRadius: PropTypes.string,
  borderBottomLeftRadius: PropTypes.string,
  borderBottomRightRadius: PropTypes.string,
  onClickAction: PropTypes.func.isRequired,
};

CustomizedButtons.defaultProps = {
  minWidth: "30px",
  //   border: "1px solid #1976d2",
};

export default CustomizedButtons;
