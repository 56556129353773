import React, { useState } from "react";
import { Image, Form } from "react-bootstrap";
import { RWebShare } from "react-web-share";
import {
  checkRestaurantClosedOrOpen,
  extractTimingData,
  getOndcRestaurantAddress,
} from "../ONDC/ondcHelperFuction.ts";
import moment from "moment";

const TitleSectionNew = (props) => {
  const { displayType, ondcRestInfo } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [callOpen, setCallOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (props?.onclicksearch) {
      props.onclicksearch(searchQuery);
    }
  };

  return (
    <div
      className={`title-section-with-search d-flex align-items-center py-3 ${
        props.className || ""
      }`}
      style={{ backgroundColor: props.bg || "#FFFFFF" }}
    >
      <div className="row w-100 m-0">
        <div
          className={`col-8 d-flex ${
            displayType === "ONDC" ? "align-items-start" : "align-items-center"
          }`}
        >
          {/* Restaurant Logo */}
          {props?.resticon && (
            <div className="w-16 h-16 rounded-lg bg-white border">
              <Image
                src={props.resticon}
                alt="Restaurant Logo"
                // width="60px" // Increased the size of the logo
                // height="60px"
                className="w-full h-full object-center rounded-lg shadow-md"
                // style={{
                //   maxWidth: "100%", // Reduced border radius for less roundness
                // }}
              />
            </div>
          )}
          <div className="mx-2">
            <div className="f20-700 px-1">{props?.title}</div>
            {displayType === "ONDC" && (
              <>
                <div className="flex items-center justify-start f20-700 px-1">
                  <p className="text-sm font-Mulish font-normal mb-0">
                    Time:{" "}
                    <span className="font-bold">
                      {moment(
                        extractTimingData(
                          ondcRestInfo?.tags,
                          "timing",
                          "time_from"
                        ),
                        "HHmm"
                      ).format("HH:mmA")}{" "}
                      -{" "}
                      {moment(
                        extractTimingData(
                          ondcRestInfo?.tags,
                          "timing",
                          "time_to"
                        ),
                        "HHmm"
                      ).format("HH:mmA")}
                    </span>
                  </p>
                  <p
                    className={`text-base font-Mulish font-bold mb-0 px-2 ${
                      checkRestaurantClosedOrOpen(ondcRestInfo?.tags)
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {checkRestaurantClosedOrOpen(ondcRestInfo?.tags)
                      ? "Open now"
                      : "Closed"}
                  </p>
                </div>
                <div className="px-1">
                  {getOndcRestaurantAddress(ondcRestInfo?.locations).map(
                    (line, index) => (
                      <span
                        className="text-sm font-Mulish font-semibold mb-0"
                        key={index}
                      >
                        {line}
                        {index === 3 && <br />}{" "}
                      </span>
                    )
                  )}
                </div>
              </>
            )}
            {(props.rating || props.reviews) && (
              <div className="d-flex align-items-center justify-content-between w-100">
                {/* Rating & Reviews Section */}
                <div className="d-flex align-items-center">
                  {/* Star Icon */}
                  <Image
                    src={require("../../assets/images/IconsandImages/starnew.png")}
                    alt="Star Icon"
                    width="14px"
                    height="14px"
                    style={{ marginRight: "5px" }}
                  />

                  {/* Rating */}
                  <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                    {props.rating || "N/A"}
                  </span>

                  {/* Reviews (Blue Link) */}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#007BFF", // Blue color
                      marginLeft: "5px",
                      fontWeight: "500",
                    }}
                  >
                    ({props.reviews || 0} reviews)
                  </span>
                </div>

                {/* Time & Distance Section */}
                {props.time && props.distance && (
                  <div className="d-flex ml-1 align-items-center">
                    {/* Timer Icon */}
                    <Image
                      src={require("../../assets/images/IconsandImages/timer.png")}
                      alt="Time Icon"
                      width="14px"
                      height="14px"
                      style={{ marginRight: "5px" }}
                    />

                    {/* Time & Distance Text */}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#555",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {props.time} min ({props.distance} KMS)
                    </span>
                  </div>
                )}
              </div>
            )}

            <div
              className="f10-700 px-1 opacity-50 nowrap "
              style={{
                color: props?.subtitlecolor ? props?.subtitlecolor : "",
              }}
            >
              {" "}
              {props.subtitle}
            </div>
          </div>
        </div>

        {/* Search Bar */}
        {props.noIconDisplay ? (
          <></>
        ) : (
          <div className="d-flex justify-content-end align-items-center col-4 px-4 mt-1">
            {props?.icon && (
              <div className=" px-2">
                <Image
                  src={props?.icon}
                  alt="icon"
                  width="16px"
                  height="16px"
                  style={{ maxWidth: "100%" }}
                  onClick={() => {
                    if (props?.onclicksearch()) props?.onclicksearch();
                  }}
                />
              </div>
            )}
            {props?.callicon && (
              <div className="px-2 mt-1" onClick={() => setCallOpen(true)}>
                <Image
                  src={require("../../assets/images/IconsandImages/call.png")}
                  alt="icon"
                  width="28px"
                  // height="24px"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
            {props?.shareIcon && (
              <div className="px-2">
                <RWebShare
                  data={props?.shareObj || ""}
                  onClick={() => console.log("shared successfully!")}
                >
                  <Image
                    src={props?.shareIcon}
                    alt="icon"
                    width="16px"
                    height="16px"
                    style={{ maxWidth: "100%" }}
                  />
                </RWebShare>
              </div>
            )}

            {props?.rightText && (
              <div
                onClick={() => props.resetfun()}
                className="d-flex justify-content-end col-4 px-4 mt-1 text-primary"
              >
                {props?.rightText || ""}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleSectionNew;
