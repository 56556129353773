import React from "react";
import { Image } from "react-bootstrap";
import { NOTAVAILABLEIMAGE } from "../../../common/enums/GlobalEnums";
import {
  CartPageAddButton,
  cartPageAddButton,
} from "../../ReusableComponent/Buttons";

function CartMenuCard({
  index,
  item,
  storedItems,
  setStoredItems,
  fullItemData,
}) {
  const incrementHander = () => {
    const updatedItem = {
      ...fullItemData,
      quantity: fullItemData.quantity + 1,
    };
    const updatedItems = [...storedItems];
    updatedItems[index] = updatedItem;
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setStoredItems(updatedItems);
  };

  const decrementHandler = () => {
    if (fullItemData.quantity === 1) {
      // If quantity is 1, remove the item from the array
      const updatedItems = storedItems.filter((item, i) => i !== index);

      sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    } else if (fullItemData.quantity > 1) {
      // If quantity is greater than 1, decrement the quantity
      const updatedItem = {
        ...fullItemData,
        quantity: fullItemData.quantity - 1,
      };

      const updatedItems = [...storedItems];
      updatedItems[index] = updatedItem;

      sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    }
  };

  return (
    <div>
      <div className="menu-card border rounded p-2 mb-2">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center ">
            <Image
              src={require(`../../../assets/images/IconsandImages/${item?.veg ? "vegexp.png" : "nonvegexp.png"
                }`)}
              alt="Image"
              width="18px"
              height="18px"
            />
            <span className="f15-700 mx-2">
              {" "}
              {item?.veg ? "Veg" : "Non-Veg"}{" "}
            </span>
          </div>
          <div className="d-flex">
            <Image
              src={require("../../../assets/images/IconsandImages/videocam.png")}
              alt="Favorite"
              width="15px"
              height={"15px"}
              className="mx-1"
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-4 p-1 flexCenter">
            <Image
              src={item?.images?.[0] || NOTAVAILABLEIMAGE}
              alt="Image"
              width="111px"
              height="111px"
              className="w-100 h-100 rounded"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-8 d-flex flex-column justify-content-between">
            <div>
              <div className="f16-700 my-2">{item?.name}</div>
              <div className="text-muted f14-500 mb-2">
                {item?.description?.length > 40
                  ? item.description.substring(0, 40) + "..."
                  : item.description}
              </div>
              <div className="text-muted f14-500 mb-2">
                Size: {fullItemData?.size?.size || "Standard"}
              </div>
              <div className="d-flex align-items-center mb-3">
                <Image
                  src={require("../../../assets/images/IconsandImages/timer.png")}
                  alt="Prep Time"
                  width="12px"
                  className="me-1"
                />
                <span className="f14-500">{item?.prepTime || 0} min</span>
              </div>
              <div className="d-flex my-2">
                <div className="d-flex ">
                  {item?.rating ? (
                    <div className="pr-1">
                      <span>
                        <Image
                          src={require("../../../assets/images/IconsandImages/warningstar.png")}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                      <span className="f14-500 opacity- mx-1">
                        {item?.rating || 0}
                      </span>
                    </div>
                  ) : null}
                  {item?.spice_level?.value && (
                    <div className="px-1 ">
                      <span className="f14-500 opacity-50 mx-1">
                        {item?.spice_level?.level}
                      </span>
                      <span>
                        <Image
                          src={require("../../../assets/images/IconsandImages/chili.png")}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                    </div>
                  )}
                </div>
                {item?.spice_level?.value && (
                  <div className="px-1 ">
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.spice_level?.level}
                    </span>
                    <span>
                      <Image
                        src={require("../../../assets/images/IconsandImages/chili.png")}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="d-flex align-items-center justify-content-end my-2">
                {/* Button to Add Note 
                <button
                  className="btn d-flex align-items-center"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => console.log("Add Note button clicked!")}
                >
                  {/* <span className="f14-400" style={{ marginRight: "8px" }}>
                    Item note
                  </span> 
                  <Image
                    src={require("../../../assets/images/IconsandImages/pencilGrey.png")}
                    alt="Edit Note"
                    width="16px"
                    height="16px"
                  />
                </button>
              </div> */}
              <div className="my-2 d-flex align-items-center justify-content-between">
                <div className="">
                  {" "}
                  {/* <AddButton
                    item={item}
                    storedItems={storedItems}
                    setStoredItems={setStoredItems}
                    orderSelected={orderSelected}
                  /> */}
                  <CartPageAddButton
                    item={fullItemData}
                    incrementHander={incrementHander}
                    decrementHandler={decrementHandler}
                  />
                </div>
                <div className="f18-700 mr-2">₹ {fullItemData?.finalPrice}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartMenuCard;
