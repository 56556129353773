import moment from "moment";
import React, { useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";

const RatingsAndReviews = ({ experienceId, data, vendorId, count }) => {
  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  // /review-rating?experienceId=63b6b0617c6756bd6c8b8eef&limit=2&page=1&type=6

  useEffect(() => {
    let query = ``;
    if (vendorId) {
      query = `?vendor_id=${vendorId}&type=2`;
    } else if (experienceId) {
      query = `?experienceId=${experienceId}&limit=2&page=1&type=6`;
    }

    if (experienceId || vendorId) {
      executeAPI(`${URL.RATINGS_REVIEWS}${query}`, "GET");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId, count]);

  return (
    <div className="p-2">
      <div className="row align-items-center">
        <div className="col-2 col-md-2">
          <Image
            src={require("../../assets/images/ExperiencePageAssets/star-bg.png")}
            alt="Ratings"
            title="Ratings"
            width={50}
          />
        </div>
        <div className="col-10">
          <span className="f20-700">
            {parseFloat(data?.expReviewed).toFixed(1) || "No Reviews"}
          </span>
          <span className="f14-400"> / {parseFloat(5.0) || ""}</span>
          <div className="d-flex align-items-center">
            <Image
              src={require("../../assets/images/ExperiencePageAssets/star.png")}
              alt="Star"
              title="Star"
              width={12}
            />
            <Image
              src={require("../../assets/images/ExperiencePageAssets/star.png")}
              alt="Star"
              title="Star"
              width={12}
            />
            <Image
              src={require("../../assets/images/ExperiencePageAssets/star.png")}
              alt="Star"
              title="Star"
              width={12}
            />
            <Image
              src={require("../../assets/images/ExperiencePageAssets/star.png")}
              alt="Star"
              title="Star"
              width={12}
            />
            <Image
              src={require("../../assets/images/ExperiencePageAssets/star.png")}
              alt="Star"
              title="Star"
              width={12}
            />
          </div>
        </div>
      </div>
      {responseData?.map((e) => (
        <div key={e?._id}>
          <div className="row mt-4">
            <div className="col-2">
              {e?.user_id?.profile_photo?.length ? (
                <Image
                  src={e?.user_id?.profile_photo?.[0]}
                  width={50}
                  alt="person"
                  title="person"
                />
              ) : null}
            </div>
            <div className="col-6">
              <div className="f12-500">
                {e?.user_id?.first_name + " " + e?.user_id?.last_name}
              </div>
              {/* <div className="f12-400">Top Critic</div> */}
            </div>

            <div className="col-4">
              {[...Array(e?.rating || 0)].map(() => {
                return (
                  <Image
                    src={require("../../assets/images/ExperiencePageAssets/star.png")}
                    width={15}
                    alt="Ratings"
                    title="Ratings"
                  />
                );
              })}
              {[...Array(5 - parseInt(e?.rating || 0))].map(() => {
                return (
                  <Image
                    src={require("../../assets/images/ExperiencePageAssets/star-white-bg.png")}
                    width={15}
                    alt="Ratings"
                    title="Ratings"
                  />
                );
              })}
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-2"></div>
            <div className="col-10">
              <div className="f12-500">{e?.review}</div>
              <div className="f10-500">
                {moment(e?.createdAt).format("Mo MMM, YYYY")}
              </div>

              <div className="mt-2 d-flex align-items-center">
                <Image
                  src={require("../../assets/images/ExperiencePageAssets/like.png")}
                  alt="Like"
                  title="Like"
                  width={10}
                />
                <div className="ml-1">0</div>
                <div className="ml-3">
                  <Image
                    src={require("../../assets/images/ExperiencePageAssets/dislike.png")}
                    alt="Like"
                    title="Like"
                    width={10}
                  />
                </div>
                <div className="ml-1">0</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* <div className="d-flex align-items-center justify-content-center mt-4">
        <span
          className="px-3 py-2 rounded text-white"
          style={{ backgroundColor: "#EE3A23" }}
        >
          View More Reviews
        </span>
      </div> */}
      <div className="d-flex mt-4">
        <Button
          className="px-3 py-2"
          style={{ backgroundColor: "#EE3A23", color: "white", border: "none" }}
        >
          Write a Review
        </Button>
      </div>
    </div>
  );
};

export default RatingsAndReviews;
