import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Image } from "react-bootstrap";
import { servicesImages } from "../../assets/images";
import { setOrderService } from "../../store/slices/orderingSlice";
import { useDispatch } from "react-redux";
import DineInDrawerNew from "../../screens/ordering/modals/DineInDrawerNew";
import CurbSideDetails from "../../screens/ordering/modals/CurbSideDetails";

const servicesData = [
  {
    label: "Take Away",
    image: servicesImages.TakeAway,
    selectedImage: servicesImages.TakeAwaySelected,
    key: "TAKE_AWAY",
  },
  {
    label: "Curb Side",
    image: servicesImages.CurbSide,
    selectedImage: servicesImages.CurbSideSelected,
    key: "CURB_SIDE",
  },
  {
    label: "Dine In",
    image: servicesImages.DineIn,
    selectedImage: servicesImages.DineInSelected,
    key: "DINE_IN",
  },
  {
    label: "Skip the Line",
    image: servicesImages.SkipTheLine,
    selectedImage: servicesImages.SkipTheLineSelected,
    key: "SKIP_THE_LINE",
  },
  {
    label: "Delivery",
    image: servicesImages.Delivery,
    selectedImage: servicesImages.DeliverySelected,
    key: "HOME_DELIVERY",
  },
  {
    label: "Waitlist",
    image: servicesImages.WaitList,
    selectedImage: servicesImages.WaitListSelected,
    key: "WAIT_LIST",
  },
  {
    label: "Reservation",
    image: servicesImages.Reservation,
    selectedImage: servicesImages.ReservationSelected,
    key: "RESERVATION",
  },
  {
    label: "Walk In",
    image: servicesImages.WalkIn,
    selectedImage: servicesImages.WalkInSelected,
    key: "WALK_IN",
  },
];

const OrderingServices = ({
  title,
  hanldeClick,
  handleFilterChang,
  selectedFilters,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // DineIn drawer open/close
  const dispatch = useDispatch();

  const handleClick = (service, index) => {
    // console.log("Clicked service:", service.label, "Index:", index);
    setSelectedIndex(index);
    handleFilterChang("subscribedServices", service.key);
    hanldeClick({ ...selectedFilters, subscribedServices: service.key });
    const orderType = service.key;
    dispatch(setOrderService({ orderType }));

    // Open the drawer for "Dine In"
    if (service.key === "DINE_IN") {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
      <Typography className="f22-800 p-3" color="initial">
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          gap: 2,
          padding: 2,
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
        }}
        className={"scrollable px-0 py-0"}
      >
        {servicesData.map((service, index) => (
          <Box
            key={index}
            onClick={() => handleClick(service, index)}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              cursor: "pointer",
              padding: 1,
              borderRadius: "8px",
              transition: "transform 0.3s ease",
              transform: selectedIndex === index ? "scale(1.05)" : "scale(1)",
            }}
          >
            <Image
              src={
                selectedIndex === index ? service.selectedImage : service.image
              }
              alt={service.label}
              roundedCircle
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                border:
                  selectedIndex === index && service.key === "DINE_IN"
                    ? "3px solid red"
                    : "none",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                color: selectedIndex === index ? "red" : "#333",
              }}
              className="f16-800 nowrap"
            >
              {service.label}
            </Typography>
          </Box>
        ))}
      </Box>
      {/* Use the existing drawer component */}
      <DineInDrawerNew open={drawerOpen} setOpen={setDrawerOpen} />
    </Box>
  );
};

export default OrderingServices;
