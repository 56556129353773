import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ondcRestaurants: [], // Rehydrate state
  ondcOrderInfo: {},
  ondcCartItems: [],
  ondcViewCartBottomInfo: {},
};

const ondcSlice = createSlice({
  name: "ondc",
  initialState,
  reducers: {
    saveOndcRestaurants: (state, action) => {
      const oldDataIds = state.ondcRestaurants.map(
        (res) => res?.message?.catalog["bpp/descriptor"]?.name
      );
      const newDataIds = [
        action.payload?.message?.catalog["bpp/descriptor"]?.name,
      ];
      // const newDataIds = action.payload?.map((res)=>res?.context?.bpp_id);

      newDataIds.forEach((newResId, index) => {
        if (action.payload?.message?.catalog["bpp/providers"]?.length > 0) {
          if (!oldDataIds.includes(newResId)) {
            state.ondcRestaurants.push(action.payload);
          }
        }
      });
    },
    saveOndcOrderInfo: (state, action) => {
      state.ondcOrderInfo = action.payload;
    },
    saveOndcCartItems: (state, action) => {
      state.ondcCartItems = action.payload;
    },
    saveOndcViewCartBottomInfo: (state, action) => {
      state.ondcViewCartBottomInfo = action.payload;
    },

    clearOndcRestaurants: (state, action) => {
      state.ondcRestaurants = [];
      state.ondcOrderInfo = {};
      state.ondcCartItems = [];
      state.ondcViewCartBottomInfo = {};
    },
  },
});

export const {
  saveOndcRestaurants,
  saveOndcOrderInfo,
  saveOndcCartItems,
  saveOndcViewCartBottomInfo,
  clearOndcRestaurants,
} = ondcSlice.actions;
export default ondcSlice.reducer;
