import moment from "moment";

/***** GET ONDC RESTAURANT FILTER *****/
export function ondcRestaurantSearch(data: any, searchTerm: string) {
  return data
    .map((item: any) => {
      const filteredProviders = item?.message?.catalog["bpp/providers"].filter(
        (provider: { descriptor: { name: string } }) =>
          provider?.descriptor?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      if (filteredProviders?.length > 0) {
        return {
          ...item,
          message: {
            ...item?.message,
            catalog: {
              ...item?.message?.catalog,
              "bpp/providers": filteredProviders,
            },
          },
        };
      }
      return {
        ...item,
        message: {
          ...item?.message,
          catalog: { ...item?.message?.catalog, "bpp/providers": [] },
        },
      };
    })
    .filter((item: any) => item?.message?.catalog["bpp/providers"]?.length > 0);
}

/***** GET ONDC MENU ITEM FILTER *****/
export function ondcRestaurantMenuFunc(data: any) {
  let ondcRestaurantData = data;
  if (
    Array.isArray(ondcRestaurantData?.categories) &&
    Array.isArray(ondcRestaurantData?.items)
  ) {
    const categories = ondcRestaurantData?.categories;
    const items = ondcRestaurantData?.items;
    const categoriesMap = new Map();
    categories.forEach((category: any) => {
      if (
        category?.tags?.some((tag: any) =>
          tag?.list?.some((tagItem: any) => tagItem.value === "custom_menu")
        )
      ) {
        categoriesMap.set(category?.id, {
          ...category,
          newItemsArr: [],
        });
      }
    });
    items.forEach((item: any) => {
      item?.category_ids?.forEach((categoryId: any) => {
        const categoryIdWithoutRank = categoryId.split(":")[0];
        const category = categoriesMap.get(categoryIdWithoutRank);
        if (category) {
          if (Array.isArray(category?.newItemsArr)) {
            category.newItemsArr.push(item);
          } else {
            console.warn(
              `Category with ID ${categoryIdWithoutRank} doesn't have a valid 'newItemsArr' array.`
            );
          }
        }
      });
    });
    const newArrayData = Array.from(categoriesMap.values());
    return newArrayData;
  } else {
    console.error("data.categories or data.items is not an array!");
  }
}

/***** GET ONDC RESTAURANT TIMING *****/
export function extractTimingData(
  data: any,
  codeType: string,
  listCodeType: string
) {
  const arrOne = data.find((item: any) => item.code === codeType.toLowerCase());
  if (arrOne) {
    const listValue = arrOne.list.find(
      (item: any) => item.code === listCodeType
    )?.value;
    return listValue;
  }
  return null;
}

/***** GET ONDC RESTAURANT OPEN AND CLOSED TIMING *****/
export function checkRestaurantClosedOrOpen(restInfoTags: any) {
  const currentTime = moment().format("HHmm");
  const restOpenTime = extractTimingData(restInfoTags, "timing", "time_from");
  const closedTime = extractTimingData(restInfoTags, "timing", "time_to");
  const restIsOpen = currentTime >= restOpenTime && currentTime <= closedTime;
  return restIsOpen;
}

/***** GET ONDC RESTAURANT ADDRESS *****/
export function getOndcRestaurantAddress(restInfoLocation: any) {
  const address = restInfoLocation.map((location: any) => location.address);
  const completeAddress = [
    address?.[0]?.building,
    address?.[0]?.locality,
    address?.[0]?.street,
    address?.[0]?.city,
    address?.[0]?.state,
    address?.[0]?.country,
    address?.[0]?.area_code,
  ];
  return completeAddress;
}

export function TruncatedText(text: string, textLength: number) {
  const maxLength = textLength;
  const truncatedText =
    text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  return truncatedText;
}

export function ondcInitialCartData() {
  const cartObj = {
    context: {},
    message: {
      order: {
        items: [],
        provider: {},
        tags: [],
        fulfillments: [],
        payment: {
          type: "ON-FULFILLMENT",
        },
        add_tip: { amount: 0 },
        donate_amount: { amount: 0 },
        base_amount: 0,
        total_amount: 0,
      },
    },
  };
  return cartObj;
}

export function setViewCartInfo(cartItemData: any, orderInfo: any) {
  let orderData = orderInfo?.message?.order;
  if (cartItemData && Object.keys(cartItemData)?.length > 0) {
    const totalItems = (cartItemData: any) => {
      let data = cartItemData;
      let totalItems = 0;
      for (const element of data) {
        totalItems += element?.quantity?.count;
      }
      return +totalItems;
    };
    const totalItemPrice = (cartItemData: any) => {
      let data = cartItemData;
      let totalPrice = 0;
      for (const element of data) {
        totalPrice += element?.price?.value * element?.quantity?.count;
      }
      return +totalPrice;
    };
    let formData = {
      quantity: totalItems(cartItemData),
      totalPrice: totalItemPrice(cartItemData).toFixed(2),
      totalAmount: (
        totalItemPrice(cartItemData) +
        +orderData?.add_tip?.amount || 0 +
        +orderData?.donate_amount?.amount || 0
      ).toFixed(2),
    };
    return formData;
  } else {
    let formData = {
      quantity: 0,
      totalPrice: 0,
      totalAmount: 0,
    };
    return formData;
  }
}
