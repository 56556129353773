import axios from "../../common/api/axios";
import { URL } from "../../common/api/urls";

export const addItemsToCartServerCall = async (token, data) => {
  try {
    return await axios.post(URL.ONDC_USER_CART, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (err) {
    console.log(err);
    return { status: false, err };
  }
};

export const getItemsAddedToCart = async (user_id, token) => {
  try {
    return await axios.get(URL.ONDC_USER_CART + "/" + user_id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (err) {
    console.log(err);
    return { status: false, err };
  }
};
