import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { Button, Col, Image, Row } from "react-bootstrap";
import {
  useNavigate,
  useParams,
  useLocation as use_location,
} from "react-router-dom";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import ScreenTemplate from "../../../components/ScreenTemplate";
import Mainheader from "../../../components/headers/Mainheader";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import MainSideBar from "../../../components/MainsideBar";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DottedHr from "../../reusableUiComponents/DottedHr";
import EastIcon from "@mui/icons-material/East";
import CartMenuCard from "./CartMenuCard";

import { isEmpty } from "../../../common/utility/helperFunc";
import { useDispatch, useSelector } from "react-redux";
import CartBar from "../../ReusableComponent/CartBar";
import {
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcViewCartBottomInfo,
} from "../../../store/slices/ondcSlice";
import { IconButton, TextField, Drawer, Alert } from "@mui/material";
import OnceMenuItemCards from "../../ONDC/Components/MenuItemCards/OnceMenuItemCards";
import { setViewCartInfo } from "../../ONDC/ondcHelperFuction.ts";

const OndcCartPage = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const propsData = use_location();
  const { ID } = useParams();
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const locationData = use_location();
  const dispatch = useDispatch();

  const [ondcOrderInfo, setOndcOrderInfo] = useState({});
  const [ondcCartItems, setOndcCartItems] = useState([]);
  const [bottomViewCartInfo, setBottomViewCartInfo] = useState({});
  const [showNotesAlert, setShowNotesAlert] = useState(false);

  const getOndcSavedOrderInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );
  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );
  const getOndcBottomViewCartInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcViewCartBottomInfo
  );

  useEffect(() => {
    if (ID) {
      if (!isEmpty(locationData) && !isEmpty(locationData?.state)) {
        const storedOndcOrderDetails = JSON.parse(
          localStorage.getItem("ondcOrderDetails")
        );
        const storedOndcCartDetails = JSON.parse(
          localStorage.getItem("ondcCartDetails")
        );
        const storedOndcViewCartBottomInfo = JSON.parse(
          localStorage.getItem("ondcViewCartBottomInfo")
        );
        if (
          !isEmpty(storedOndcOrderDetails) &&
          !isEmpty(storedOndcCartDetails)
        ) {
          dispatch(saveOndcOrderInfo(storedOndcOrderDetails));
          dispatch(saveOndcCartItems(storedOndcCartDetails));
          dispatch(saveOndcViewCartBottomInfo(storedOndcViewCartBottomInfo));
        } else {
          dispatch(saveOndcOrderInfo({}));
          dispatch(saveOndcCartItems([]));
          dispatch(saveOndcViewCartBottomInfo({}));
        }
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (!isEmpty(getOndcSavedOrderInfo)) {
      setOndcOrderInfo(getOndcSavedOrderInfo);
    } else {
      setOndcOrderInfo({});
    }
    if (!isEmpty(getOndcSavedCartItems)) {
      setOndcCartItems(getOndcSavedCartItems);
    } else {
      setOndcCartItems([]);
    }
    if (!isEmpty(getOndcBottomViewCartInfo)) {
      setBottomViewCartInfo(getOndcBottomViewCartInfo);
    } else {
      setBottomViewCartInfo({});
    }
  }, [getOndcSavedOrderInfo, getOndcSavedCartItems, getOndcBottomViewCartInfo]);

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const navigateToViewMenu = () => {
    navigate(`/restaurant/${ID}/viewmenu`);
  };

  const [notes, setNotes] = useState(
    ondcOrderInfo?.message?.order?.notes || ""
  );
  const submitNotes = () => {
    let orderInfo = ondcOrderInfo;
    if (!isEmpty(notes)) {
      if (Object.isExtensible(orderInfo?.message?.order)) {
        orderInfo.message.order.notes = notes;
      } else {
        orderInfo.message = {
          ...orderInfo.message,
          order: {
            ...orderInfo.message.order,
            notes: notes,
          },
        };
      }
      localStorage.setItem("ondcOrderDetails", JSON.stringify(orderInfo));
      dispatch(saveOndcOrderInfo(orderInfo));
      setNotes("");
    } else {
      setShowNotesAlert(true);
    }
  };

  const intialOrderCall = () => {
    navigate(
      `/ondc/${ID}/ondctrack/${ondcOrderInfo?.message?.order?.provider?.id}`
    );
  };

  const content = (
    <React.Fragment>
      <div
        className={
          "bg-[#80808014] px-2 py-3 rounded-md flex items-center justify-between"
        }
      >
        <div className="w-full">
          <p className="text-sm text-lightTextColor font-Mulish font-bold">
            Order References :{" "}
            <span className="text-base text-black font-bold ">DELIVERY</span>
          </p>
          <Row>
            <Col xs={4} md={2} className="flex items-center justify-start">
              <Image
                src={require("../../../assets/images/IconsandImages/calexp.png")}
                alt="icon"
                className="object-contain w-6 h-6"
                style={{ maxWidth: "100%" }}
              />
              <div className="f14-700 ml-2">
                {moment().format("DD MMM YYYY")}
              </div>
            </Col>
            <Col xs={4} md={2} className="flex items-center justify-start">
              <Image
                src={require("../../../assets/images/IconsandImages/clockexp2.png")}
                alt="icon"
                className="object-contain w-6 h-6"
                style={{ maxWidth: "100%" }}
              />
              <div className="f14-700 ml-2">{moment().format("hh:mm A")}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-flex justify-content-between px-2 py-3">
        <div className="f18-700">Order Details</div>
        <button
          className="text-sm font-Mulish font-semibold"
          style={{ color: "#2196f3" }}
          onClick={navigateToViewMenu}
        >
          Add More Items
        </button>
      </div>
      <Row>
        {!isEmpty(ondcCartItems) &&
          ondcCartItems?.length > 0 &&
          ondcCartItems?.map((items, index) => {
            return (
              <Col sm={12} md={6} key={items?.id}>
                {/* <CartMenuCard
                  index={index}
                  item={items.items}
                  storedItems={ondcCartItems}
                  setStoredItems={setOndcCartItems}
                  fullItemData={items}
                /> */}
                <OnceMenuItemCards
                  viewType={1}
                  item={items}
                  restData={ondcOrderInfo?.message?.order?.provider}
                  // ondcOrderInfo={ondcOrderInfo}
                  // ondcCartItems={ondcCartItems}
                  // setPriceAlertModal={setPriceAlertModal}
                />
              </Col>
            );
          })}
      </Row>
      {!isEmpty(ondcCartItems) && ondcCartItems?.length ? (
        <>
          <div className="p-2">
            <div className="f14-700 d-flex align-items-center">
              {" "}
              <DescriptionOutlinedIcon mx={4} />
              Write Notes For Kitchen
            </div>
            <div className="my-2">
              <div className="w-full">
                <TextField
                  id="outlined-basic-kitchen"
                  placeholder="I want less sugar."
                  variant="standard"
                  size="small"
                  className="border bg-white shadow-sm rounded-lg"
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  sx={{ border: "none" }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="send"
                        onClick={() => submitNotes()}
                      >
                        <EastIcon
                          sx={{
                            color: "whitesmoke",
                            backgroundColor: "#96ade1",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
            {showNotesAlert && (
              <Alert severity="error" onClose={() => setShowNotesAlert(false)}>
                Please Enter Valid instruction
              </Alert>
            )}
          </div>
          {/* <OffersOrdering
            offers={offeresponseData}
            orderType={orderType}
            isInput={isInput}
            prevOfferId={prevOfferId}
            cancelTrigger={() =>
              setState({
                ...state,
                offerModal: false,
                isInput: false,
              })
            }
            promoCode={couponCode}
            discountMessage={offerErrorMsg}
            onApplyClickHandler={(actionType, data) =>
              onApplyOffers(actionType, data)
            }
            onChangeText={(text) => {
              setState({
                ...state,
                couponCode: text.toUpperCase(),
              });
            }}
            isVisible={offerModal}
            // showModal={() =>
            //   setState({
            //     ...state,
            //     offerModal: true,
            //   })
            // }
            showModal={() => {
              //console.log("Opening offers modal...");
              setState((prevState) => ({
                ...prevState,
                offerModal: true,
              }));
            }}
            orderData={OrderData}
            onClickRemoveApplyPromotion={onClickRemoveApplyPromotion}
            showLoginModal={setshowOtpModal}
          /> */}
          <div className="pb-5">
            <div className="p-3 f14-700">Order Summary</div>
            <DottedHr />
            <div className="p-3 f14-700">
              <div className="flex-between my-2">
                <div>Original Item Value</div>
                <div>₹{ondcOrderInfo?.message?.order?.base_amount}</div>
              </div>
              {/* {!isEmpty(OrderData?.coupon_code) &&
              OrderData?.coupon_code.length > 0 &&
              OrderData?.discount?.amount > 0 ? (
                <div className="flex-between my-2">
                  <div>
                    Discount{" "}
                    <span style={{ color: assets.colors.primary }}>
                      ({OrderData?.coupon_code})
                    </span>
                  </div>
                  <div
                    style={{ color: assets.colors.primary }}
                  >{`- ₹ ${OrderData?.discount?.amount.toFixed(2)}`}</div>
                </div>
              ) : null} */}
              {/* <div className="my-2">
                <div className="flex-between">
                  <button onClick={() => setShowtax(!showTax)}>
                    {" "}
                    {showTax ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                    Taxes
                  </button>
                  <div>
                    ₹{parseFloat(summary?.taxes?.gstTotal).toFixed(2) || 0}
                  </div>
                </div>
                {showTax && (
                  <div className="pl-4 py-1 f12-500">
                    {summary?.taxes?.gstTotal
                      ? Object.entries(summary?.taxes?.gst).map(
                          ([keyValue, value]) => (
                            <div
                              className="flex-between"
                              key={keyValue + value}
                            >
                              <div>{keyValue}</div>
                              <div>₹{value.toFixed(2)}</div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                )}
              </div>
              <div className="my-2">
                <div className="flex-between">
                  <button onClick={() => SetShowcharges(!showsurCharges)}>
                    {" "}
                    {showsurCharges ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                    Surcharges
                  </button>
                  <div>
                    ₹{parseFloat(summary?.taxes?.surTotal).toFixed(2) || 0}
                  </div>
                </div>
                {showsurCharges && (
                  <div className="pl-4 py-1 f14-500">
                    {summary?.taxes?.surTotal
                      ? Object.entries(summary?.taxes?.surCharges).map(
                          ([keyName, value]) => (
                            <div className="flex-between" key={keyName + value}>
                              <div>{keyName}</div>
                              <div>₹{value}</div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                )}
              </div> */}
              <AddTip
                OrderData={ondcOrderInfo}
                setOrderData={setOndcOrderInfo}
              />
              <DonateUdbhav
                OrderData={ondcOrderInfo}
                setOrderData={setOndcOrderInfo}
              />
              <hr />
              <div className="flex-between f16-700">
                <div>Grand Total</div>
                <div>₹{ondcOrderInfo?.message?.order?.total_amount || 0}</div>
              </div>
              <hr />
            </div>
          </div>
        </>
      ) : (
        <div className="p-2 text-center">
          <div style={{ height: "30vh" }}></div>
          <div className="f18-700 mt-4">Your Cart is empty</div>
          <div className="f16-500 text-grey my-4">
            Please add something from menu to enjoy the delicious food and our
            services
          </div>
        </div>
      )}
      <div className="bg-white fixed bottom-14 md:bottom-0 left-0 md:left-[17%] w-full z-[100]">
        {/* <div className="row w-100 m-0">
          <div className="col-10  p-0"> */}
        <div className="col-12 p-0">
          {!isEmpty(ondcOrderInfo) &&
            !isEmpty(ondcCartItems) &&
            ondcCartItems?.length > 0 && (
              <CartBar
                setshowOtpModal={() => {}}
                intialOrderCall={intialOrderCall}
                from="ONDC"
                fromPage="ONDC_CHECKOUT"
                viewCartInfo={bottomViewCartInfo}
              />
            )}
        </div>
        {/* </div>
        </div> */}
      </div>
    </React.Fragment>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
};

OndcCartPage.propTypes = {};

export default OndcCartPage;

function AddTip({ OrderData, setOrderData }) {
  const dispatch = useDispatch();
  const [showTipDrawer, setShowTipDrawer] = useState(false);
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(
    OrderData?.message?.order?.add_tip?.amount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const onClickCommonAddRemoveAmount = async (value, actionBy) => {
    let orderInfoData = OrderData;
    let newData = {};
    let finalData;
    if (Object.isExtensible(orderInfoData?.message?.order)) {
      orderInfoData.message.order.add_tip.amount = +value;
    } else {
      newData = Object.assign({}, orderInfoData, {
        message: Object.assign({}, orderInfoData.message, {
          order: Object.assign({}, orderInfoData.message.order, {
            add_tip: Object.assign({}, orderInfoData.message.order.add_tip, {
              amount: +value,
            }),
          }),
        }),
      });
      finalData = setViewCartInfo(newData?.message?.order?.items, newData);
      newData.message.order.total_amount = finalData?.totalAmount;
    }
    setTipAmount(+value);
    dispatch(saveOndcOrderInfo(newData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
    await Promise.all([
      localStorage.setItem("ondcOrderDetails", JSON.stringify(newData)),
      localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData)),
    ]);
  };

  const handleAddTip = async (e) => {
    onClickCommonAddRemoveAmount(e.target.value, "ADD_TIP");
    setShowTips(false);
  };
  const handleTipLearnMore = () => {
    setShowTipDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = async () => {
    if (tiPAmount > 0) {
      onClickCommonAddRemoveAmount(0, "REMOVE_TIP");
      setShowTips(!showTips);
    } else {
      setShowTips(true);
    }
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    onClickCommonAddRemoveAmount(customAmount, "CUSTOM_TIP");
    setShowTips(false);
    setcustom(false);
  };

  return (
    <>
      <div className="add-tip">
        <div>
          Add Tip{" "}
          <button
            className="f12-500 ml-2"
            style={{ color: "#2196f3", cursor: "pointer" }}
            onClick={handleTipLearnMore}
          >
            Learn More
          </button>
        </div>
        <button className="primary-color f12-700" onClick={handleChange}>
          {tiPAmount > 0 ? "Remove Amount" : "Change Amount"}
        </button>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Tip Modal */}
      <Drawer
        anchor="bottom"
        open={showTipDrawer}
        onClose={() => setShowTipDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div
          style={{
            padding: "20px",
            borderRadius: "16px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}> Add Tip</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/addtipNEW.png")}
              alt="Udbhav"
              style={{ maxWidth: "300px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.6" }}>
            A Customer may at it's sole discretion add a certain amount as "Tip"
            for the services provided through the Amealio Platform.Any amount
            recieved towards Tips shall be non-refundable except in the
            following circumstances: A. Order is auto-cancelled before the
            acceptance of the order bythe Merchant. b. If the Order is cancelled
            by the Merchant due to such as non-availability of food items
            ordered by the customer, non-availability of the service, or any
            other reasons attributable to the Merchant. However, in case of
            partial cncellation or modifications in the order/ Booking, the
            amount contributed towards Tips shall be non-refundableAmealio
            reserves the right, at its sole discretion, to refuse the refund of
            the amount pay towards Tips for any reason what so ever, without
            assigning any reason to the customer and the customer accepts and
            undertakes to not raise any dispute on the same.
          </p>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={() => {
              setShowTipDrawer(false);
              setShowTips(true);
            }}
          >
            Add Tip
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export function DonateUdbhav({ OrderData, setOrderData }) {
  const dispatch = useDispatch();
  const [showDonatePopup, setShowDonatePopup] = useState(false);
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(
    OrderData?.message?.order?.donate_amount?.amount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const onClickCommonAddRemoveAmount = async (value, actionBy) => {
    let orderInfoData = OrderData;
    let newData = {};
    let finalData;
    if (Object.isExtensible(orderInfoData?.message?.order)) {
      orderInfoData.message.order.donate_amount.amount = +value;
    } else {
      newData = Object.assign({}, orderInfoData, {
        message: Object.assign({}, orderInfoData.message, {
          order: Object.assign({}, orderInfoData.message.order, {
            donate_amount: Object.assign(
              {},
              orderInfoData.message.order.donate_amount,
              {
                amount: +value,
              }
            ),
          }),
        }),
      });
      finalData = setViewCartInfo(newData?.message?.order?.items, newData);
      newData.message.order.total_amount = finalData?.totalAmount;
    }
    setTipAmount(+value);
    dispatch(saveOndcOrderInfo(newData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
    await Promise.all([
      localStorage.setItem("ondcOrderDetails", JSON.stringify(newData)),
      localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData)),
    ]);
  };

  const handleAddTip = (e) => {
    onClickCommonAddRemoveAmount(e.target.value, "ADD_DONATE");
    setShowTips(false);
  };

  const handleDonateLearnMore = () => {
    setShowDonateDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    if (tiPAmount > 0) {
      onClickCommonAddRemoveAmount(0, "REMOVE_DONATE");
      setShowTips(!showTips);
    } else {
      setShowTips(true);
    }
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    onClickCommonAddRemoveAmount(customAmount, "CUSTOM_DONATE");
    setShowTips(false);
    setcustom(false);
  };

  return (
    <>
      <div className="add-tip">
        <div>
          Donate to Udbhav{" "}
          {/* <span className="f12-500" style={{ color: "#2196f3" }}>
            Learn More
          </span> */}
          <button
            className="f12-500"
            style={{ color: "#2196f3", cursor: "pointer", fontWeight: "500" }}
            onClick={handleDonateLearnMore}
          >
            Learn More
          </button>
        </div>
        <button
          className="primary-color f12-700"
          onClick={handleChange}
          style={{ marginRight: "70px" }}
        >
          {tiPAmount > 0 ? "Remove Donation" : "Change Amount"}
        </button>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Udbhav Modal */}
      <Drawer
        anchor="bottom"
        open={showDonateDrawer}
        onClose={() => setShowDonateDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2 style={{ fontWeight: "bold" }}>Donate To Udbhav</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/udbhav.png")}
              alt="Udbhav"
              style={{ maxWidth: "150px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "16px", color: "#333", lineHeight: "1.6" }}>
            Udbhav is a foundation focused on providing resources to the
            underprivileged sectors while creating a lasting impact that
            empowers communities. We cater to families in need of bare
            necessities and motivate children to study by developing specialized
            programs to improve their quality of life. Driven by the youth,
            Udbhav willingly partners up with similar organizations to help
            create change. We believe in creating a sustainable world by
            participating in drives that address environmental concerns. A
            recent partnership with the Akshaya Patra organization led to 500
            food packets delivered to the families and people most affected by
            the pandemic. A host of partnerships and events that give back to
            society are on the horizon. Be a part of this and help us to change
            the world!
          </p>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "25px",
              textTransform: "capitalize",
              width: "100%",
            }}
            onClick={() => window.open("https://visionudbhav.org/", "_blank")}
          >
            Visit Udbhav
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
