import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Image } from "react-bootstrap";
import { ORDER_TYPES } from "../../../common/enums/OrderEnums";
import { useSelector } from "react-redux";

function DineInDrawerNew({ open, setOpen }) {
  const path = window.location.pathname;
  const UserData = useSelector((state) => state.user.userDetails);
  const restData = JSON.parse(sessionStorage.getItem("restaurantData"));
  const defaultOrderType = sessionStorage.getItem("defaultOrderType");
  const subscriptionData = restData?.subscription?.casual_dining
    ? restData?.subscription?.casual_dining_status
    : restData?.subscription?.fast_food_dining
    ? restData?.subscription?.fast_food_dining_status
    : restData?.subscription?.multi_service_business
    ? restData?.subscription?.multi_service_business_status
    : {};
  const maxPaxValue = subscriptionData?.ordering?.dine_in_order?.maximum_person;
  const [tableNumber, setTableNumber] = useState("");
  const [paxNum, setPaxNum] = useState("");
  const [paxNumber, setPaxNumber] = useState("");

  const [initialValues, setInitialValues] = useState({
    user_details: {
      first_name: UserData?.first_name || "",
      last_name: UserData?.last_name || "",
      country_code: UserData?.country_code || "",
      mobile_number: UserData?.mobile_number || "",
    },
    order_type: ORDER_TYPES.indexOf(defaultOrderType),
    orderSelected: defaultOrderType,
    now: true,
    nowDate: new Date(),
    later_date: "",
    later_time: "",
    actual_date: "",
    later_formDated_date: "",
    delivery_partner: 1,
    dine_in_details: {
      table_number: "",
    },
    totalDinePax: "",
    curb_side_details: {
      vehical_type: 4,
      vehical_type_value: "",
      number: "",
      make: "",
      model: "",
      year: "",
      color: "",
      pickUpPoint: null,
    },
  });

  useEffect(() => {
    if (defaultOrderType) {
      setInitialValues((prevState) => ({
        ...prevState,
        order_type: ORDER_TYPES.indexOf(defaultOrderType),
        orderSelected: defaultOrderType,
      }));
    }
  }, [defaultOrderType]);

  const handleSubmitOrder = () => {
    if (paxNumber > maxPaxValue) {
      return;
    } else {
      initialValues.dine_in_details.table_number = tableNumber;
      initialValues.totalDinePax = paxNum || paxNumber;
      sessionStorage.setItem("order_details", JSON.stringify(initialValues));
      sessionStorage.setItem("DineInSkip", "proceed");
      setOpen(false);
    }
  };

  const handleSkip = () => {
    sessionStorage.setItem("DineInSkip", "skipped");
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="dine-in-dialog-title"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent>
        <div
          className="mx-1 mt-2
        "
        >
          <div className="my-2">
            <div className="f20-700 my-1 d-flex align-items-center">
              <span className="pr-1"></span>
              Enter Table Number
            </div>
            <div className="ml-1 d-flex align-items-center f14-500 text-grey">
              Enter Table or Seat No. available at your table
            </div>
            <div className="my-2">
              <TextField
                id="outlined-basic"
                label="Table No"
                variant="outlined"
                size="small"
                className="customTexfeild"
                fullWidth
                value={tableNumber}
                onChange={(e) => setTableNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="my-3">
            <div className="f20-700 my-1">
              <span className="pr-1"></span>
              Party Size
            </div>
            <div className=" ml-1 d-flex align-items-center f14-500 text-grey">
              Please Enter the number of Guests dining in
            </div>
            <div className="d-flex my-3">
              {[2, 3, 4, 5, 6].map((e) => {
                return (
                  <div className="col-2 p-2" key={e}>
                    <div
                      onClick={() => setPaxNum(e)}
                      className="f14-400 button px-1 py-2 text-center"
                      style={{
                        backgroundColor: paxNum === e ? "#fc5a47" : "",
                        color: paxNum === e ? "#fff" : "#000000",
                      }}
                    >
                      {e}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="my-2">
              <TextField
                id="outlined-basic"
                label="Type No. of People"
                variant="outlined"
                size="small"
                fullWidth
                className="customTexfeild"
                type="number"
                value={paxNumber}
                onChange={(e) => setPaxNumber(e.target.value)}
                error={paxNumber > maxPaxValue}
                helperText={
                  paxNumber > maxPaxValue
                    ? `Maximum party size is ${maxPaxValue}  PAX.`
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <Box
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          my={1}
          px={2}
        >
          {path.includes("cartpage") ? null : (
            <Button
              variant="outlined"
              size="large"
              onClick={handleSkip}
              style={{
                borderRadius: "15px",
                color: "#EE3A23",
                border: "1px solid #EE3A23",
                textTransform: "capitalize",
              }}
              className="bg-white mt-2 mr-2"
              fullWidth
            >
              Skip
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            style={{
              borderRadius: "15px",
              border: "1px solid #C4C4C4",
              color: "white",
              backgroundColor:
                tableNumber || paxNum || paxNumber ? "#fc5a47" : "#EE3A23",
              textTransform: "capitalize",
            }}
            className=" mt-2 mr-2"
            disabled={!(tableNumber || paxNum || paxNumber)}
            fullWidth
            onClick={handleSubmitOrder}
          >
            Continue
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DineInDrawerNew;
