import React, { useEffect } from "react";
import { assets } from "../../../assets/assets";
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ORDER_TYPES, ORDER_TYPE_NAME } from "../../../common/enums/OrderEnums";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { isEmpty } from "../../../common/utility/helperFunc";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
function OffersOrdering({
  offers,
  orderType,
  isInput,
  prevOfferId,
  cancelTrigger,
  promoCode,
  discountMessage,
  onApplyClickHandler,
  onChangeText,
  isVisible,
  showModal,
  orderData,
  onClickRemoveApplyPromotion,
  showLoginModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerWidth = isMobile ? "100%" : 500;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  useEffect(() => {
    //console.log(" visibility", isVisible);
  }, [isVisible]);
  useEffect(() => {
    // console.log("Fetched offers:", offers);
  }, [offers]);

  const AccessToken =
    useSelector((state) => state.user.userDetails.accessToken) ||
    sessionStorage.getItem("accessToken") ||
    JSON.parse(localStorage.getItem("userDetails"))?.accessToken;

  return (
    <div>
      <div className="flex-between p-2">
        <div className="f16-700"> Apply Promotions</div>
        <div
          className="f14-500"
          style={{
            color:
              !isEmpty(orderData?.coupon_code) &&
              orderData?.coupon_code.length > 0 &&
              orderData?.discount?.amount > 0
                ? assets.colors.primary
                : assets.colors.defaultBlue,
          }}
          onClick={() => {
            //console.log("Offers button clicked");
            if (
              !isEmpty(orderData?.coupon_code) &&
              orderData?.coupon_code.length > 0 &&
              orderData?.discount?.amount > 0
            ) {
              onClickRemoveApplyPromotion(orderData);
            } else {
              if (!AccessToken) {
                showLoginModal(true);
              } else {
                showModal();
              }
            }
          }}
        >
          {!isEmpty(orderData?.coupon_code) &&
          orderData?.coupon_code.length > 0 &&
          orderData?.discount?.amount > 0
            ? "Remove"
            : "Show Available Offers"}{" "}
        </div>
      </div>
      {!isEmpty(orderData?.coupon_code) &&
      orderData?.coupon_code.length > 0 &&
      orderData?.discount?.amount > 0 ? (
        <>
          <div className="d-flex">
            <Image
              src={require("../../../assets/images/IconsandImages/rightSuccess.png")}
              width={28}
              className="mx-2"
            />
            <div className="f14-700 text-grey">{`₹${
              orderData?.discount?.amount +
              " " +
              orderData?.coupon_code +
              " applied!"
            }`}</div>
          </div>
        </>
      ) : null}
      {/* <Drawer
        anchor="bottom"
        open={isVisible}
        onClose={cancelTrigger}
        PaperProps={{
          style: {
            width: drawerWidth,
            margin: "auto",
            height: "100vh",
          },
        }}
      > */}
      <Drawer
        anchor="bottom"
        open={isVisible}
        onClose={cancelTrigger}
        PaperProps={{
          style: {
            width: drawerWidth,
            margin: "auto",
            height: "100vh",
          },
        }}
      >
        <div className="p-2">
          <div>
            <div>
              <KeyboardBackspaceIcon onClick={() => cancelTrigger()} />
            </div>
          </div>
          <div>
            <div className="my-2 ">
              <TextField
                id="outlined"
                placeholder="Have a Promo Code?"
                variant="outlined"
                size="small"
                className="customTexfeild"
                fullWidth
                value={promoCode}
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
                sx={{ borderRadius: "16px" }}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="outlined"
                      size="small"
                      //  color="#505050"
                      sx={{
                        borderRadius: "16px",
                        color: "grey",
                        //borderColor: 'grey',
                        textDecoration: "none",
                      }}
                      onClick={() => onApplyClickHandler("INPUT", {})}
                    >
                      Apply
                    </Button>
                  ),
                }}
              />
              {discountMessage ? (
                <div style={{ color: assets.colors.red }}>
                  {" "}
                  {discountMessage}{" "}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="mt-2">
            <div className="f16-700 mt-2">
              <Image
                src={require("../../../assets/images/IconsandImages/av_offers.png")}
                width={28}
                className="mx-2"
              />{" "}
              Available Offers
            </div>

            {offers && offers.length !== 0 ? (
              offers.map((offer) => {
                return (
                  <>
                    {(offer?.service_type &&
                      offer?.service_type?.length > 0 &&
                      offer?.service_type.includes(
                        ORDER_TYPE_NAME[ORDER_TYPES.indexOf(orderType)]
                      )) ||
                    offer?.service_type.includes("All") ? (
                      !offer.hidden && (
                        <>
                          <hr />
                          <div>
                            <div className="f14-500 my-1"> {offer.name}</div>
                            <div className="f12-500 text-grey my-1">
                              {offer?.description}
                            </div>
                            <div className="f16-700 flex-between">
                              {" "}
                              <span>use code - {offer?.coupon_code}</span>
                              <span>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ borderRadius: "16px" }}
                                  onClick={() =>
                                    onApplyClickHandler("NOT_INPUT", offer)
                                  }
                                >
                                  {" "}
                                  Apply
                                </Button>
                              </span>
                            </div>
                            <Divider />
                          </div>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            ) : (
              <div className="f16-500 my-4 text-center">
                <hr /> No Available Offers{" "}
              </div>
            )}
          </div>

          <div>
            <div className="f16-700">
              {" "}
              <Image
                src={require("../../../assets/images/IconsandImages/un_offers.png")}
                width={28}
                className="mx-2"
              />{" "}
              Unavailable Offers
            </div>
            <hr />
            <div className="f16-500 my-4 text-center">
              {" "}
              No Unavailable Offers
            </div>
          </div>
        </div>
      </Drawer>
      <hr />
    </div>
  );
}

export default OffersOrdering;
