import React, { useEffect, useState } from "react";
import { Star, ThumbsUp, ThumbsDown, MessageSquare } from "lucide-react";
import { assets } from "../../../assets/assets";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { URL } from "../../../common/api/urls";
import useApi from "../../../common/api/hooks/useApi";
import WriteReview from "../../ReusableComponent/WriteReview";
import WriteReviewNew from "../../ReusableComponent/WriteReviewNew";
import RatingsAndReviewDrawer from "../../experience-details/RatingsAndReviewDrawer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const ExpReviewRating = ({ experienceId, data, vendorId, count }) => {
  // const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));
  const userAuth = JSON.parse(localStorage.getItem("userDetails"));

  // const rating = 4.2;
  const maxRating = 5.0;
  const [isLoading, setIsLoading] = useState(true);
  const { handleRequest, loading } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  useEffect(() => {
    let query = ``;
    if (vendorId) {
      query = `?vendor_id=${vendorId}&type=2`;
    } else if (experienceId) {
      query = `?experienceId=${experienceId}&limit=2&page=1&type=6`;
    }

    if (experienceId || vendorId) {
      executeAPI(`${URL.RATINGS_REVIEWS}${query}`, "GET");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId, count]);

  const handleLikeAndDislike = async (type, id) => {
    try {
      const response = await handleRequest(
        "patch",
        `${URL.RATINGS_REVIEWS}/${id}?${type}=true`,
        { [type]: true },
        {
          headers: { Authorization: userAuth.accessToken },
          successMessage: "Successfully updated favorites",
          errorMessage: "Favorites update failed",
          onSuccess: async (response) => {
            setIsLoading(false);
            // 🔄 Re-fetch updated reviews
            let query = "";
            if (vendorId) {
              query = `?vendor_id=${vendorId}&type=2`;
            } else if (experienceId) {
              query = `?experienceId=${experienceId}&limit=2&page=1&type=6`;
            }
            executeAPI(`${URL.RATINGS_REVIEWS}${query}`, "GET");
          },
          onError: (error) => {
            console.error("🚨 API Request Error:", error);
            setIsLoading(false);
          },
        }
      );

      console.log("📌 Response from PATCH request:", response);
    } catch (error) {
      console.error("🚨 Error in handleLikeAndDislike:", error);
    }
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <Star
          key={index}
          style={{
            // color: "green",
            // fill: "green",
            color: index < rating ? "green" : "gray",
            fill: index < rating ? "green" : "none",
            height: "1rem",
            width: "1rem",
          }}
          className="mr-1"
        />
      ));
  };
  // console.log("resposnedata", responseData);
  const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0; // Handle no reviews case

    // Extract ratings and calculate the average
    const totalRating = reviews.reduce(
      (sum, review) => sum + (review.rating || 0),
      0
    );
    return (totalRating / reviews.length).toFixed(1); // Keep one decimal place
  };
  const rating = calculateAverageRating(responseData);

  return (
    <div className="max-w-6xl my-4">
      <div className="d-flex justify-content-between items-center mb-6 ">
        <h5 className="card-title mb-0" style={{ fontWeight: "bold" }}>
          Reviews & Ratings
        </h5>
        <Button
          onClick={() => setIsModalOpen(true)}
          className="text-primary hover:underline"
        >
          Write a Review
        </Button>
      </div>
      <RatingsAndReviewDrawer
        open={isModalOpen}
        setOpen={setIsModalOpen}
        expData={data}
        UserData={JSON.parse(sessionStorage.getItem("userInformation"))}
        setCount={() => {}}
        count={count}
      />

      <div className="bg-white rounded-lg my-3">
        <div className="d-flex flex-col sm:flex-row justify-content-start align-items-start sm:items-center gap-4">
          <div
            className="bg-success rounded-3 d-flex align-items-center justify-content-center"
            style={{ height: "3.5rem", width: "3.5rem" }}
          >
            <Star
              style={{
                color: "white",
                fill: "white",
                height: "2rem",
                width: "2rem",
              }}
            />
          </div>
          <div>
            <span className="text-3xl font-bold">{rating}</span>
            <span className="text-gray-500">/{maxRating}</span>
            <div className="flex">{renderStars(Math.floor(rating))}</div>
          </div>
        </div>
      </div>

      <div className=" p-0">
        {/* <div class="row row-cols-1 row-cols-md-2 row-cols-lg-6 g-3"> */}
        <div class="d-flex row-cols-1 row-cols-lg-6 overflow-auto gap-3 ">
          {responseData?.map((review) => (
            <div
              key={review.id}
              className="p-4 gap-3 col-lg-4 col-md-12 rounded-3"
              style={{ backgroundColor: assets.colors.ratings_card_bg }}
            >
              <div className="d-flex align-items-center justify gap-3 mb-3">
                <div
                  className="bg-gray-200 rounded-circle overflow-hidden"
                  style={{ height: "3.3rem", width: "3.3rem" }}
                >
                  <img
                    src={review?.user_id?.profile_photo?.[0]}
                    alt={review.author}
                    className="object-fill"
                    style={{ height: "4rem", width: "4rem" }}
                  />
                </div>

                <div className="d-flex align-items-start justify-content-center flex-column">
                  {/* <h3 className="font-semibold">{review.author}</h3> */}
                  <p className="text-sm text-gray-600 m-0">
                    {review?.user_id?.first_name +
                      " " +
                      review?.user_id?.last_name}
                  </p>
                  <p className="text-sm text-muted">Top Critic</p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="mb-3">
                  <span
                    className="text-orange-800 px-3 py-1 rounded-5 text-sm"
                    style={{ backgroundColor: assets.colors.ratings_type_bg }}
                  >
                    {/* {review.category} */}
                    General
                  </span>
                </div>

                <div className="flex mb-3">{renderStars(review.rating)}</div>
              </div>

              <p className="text-gray-700 mb-4">{review?.review}</p>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-4">
                  <div
                    className="d-flex align-items-center gap-1 text-gray-600 hover:text-gray-800"
                    onClick={() => {
                      handleLikeAndDislike("like", review._id);
                    }}
                  >
                    <ThumbsUp className="w-4 h-4" />
                    <span>{review?.likes?.length}</span>
                  </div>
                  <div
                    className="d-flex align-items-center gap-1 text-gray-600 hover:text-gray-800"
                    onClick={() => {
                      handleLikeAndDislike("dislike", review._id);
                    }}
                  >
                    <ThumbsDown className="w-4 h-4" />
                    <span>{review?.dislikes?.length}</span>
                  </div>
                </div>

                <div className="flex items-center gap-1 text-gray-600 hover:text-gray-800">
                  <MessageSquare className="w-4 h-4" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Write Review */}
      {/* <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="write-review-modal"
        aria-describedby="write-a-review-for-experience"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            bgcolor: "transparent",
            boxShadow: "none",
            p: 0,
          }}
        >
          {/* <WriteReview reviewData={responseData?.[0]} /> */}
      {/* <WriteReviewNew reviewData={responseData?.[0]} /> 
        </Box>
      </Modal> */}
    </div>
  );
};

export default ExpReviewRating;
