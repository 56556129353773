import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainSideBar from "../../components/MainsideBar";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useAuth from "../../common/hooks/useAuth";
import { useState } from "react";
import Loader from "../../components/loader";
import { URL } from "../../common/api/urls";
import useApi from "../../common/api/hooks/useApi";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import ExpRestaurantCard from "./Components/ExpRestaurantCard";
import ExperiencePackage from "./Components/ExpPackage";
import AboutRestaurantCard from "./Components/AboutRestaurnatCard";
import Icons from "../../components/Icons";
import ExpReviewRating from "./Components/ExpReviewRatings";
import { assets } from "../../assets/assets";
import { useParams } from "react-router-dom";
import { useAxios } from "../../common/api/hooks/useAxios";
import { getActivityTimings } from "../../common/utility/expFunctions";
import moment from "moment";
import { useSelector } from "react-redux";

//const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));

const ExperienceBooking = ({ availability = "All Day" }) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const userAuth = useSelector((state) => state.user.userDetails);

  const [count, setCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [communityRes, setCommunityRes] = useState();
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const [
    // eslint-disable-next-line
    restaurantCancel,
    restaurantResponseData,
    // eslint-disable-next-line
    restaurantError,
    // eslint-disable-next-line
    restaurantLoaded,
    // eslint-disable-next-line
    restaurantReset,
    restaurantExecuteAPI,
  ] = useAxios();

  // Configuration
  let getFilledExpData = JSON.parse(sessionStorage.getItem("expBookData"));
  const { restaurantId, experienceId } = useParams();

  const handleLogout = () => {
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem("userOtpDetails");
    sessionStorage.removeItem("userOtpPayload");
    navigate("/");
  };

  useEffect(() => {
    if (restaurantId !== undefined && restaurantId !== null) {
      restaurantExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        "GET",
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, "GET");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    (async () => {
      if (location.lat) {
        // validate the token first
        await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
          headers: { Authorization: userAuth.accessToken },
          successMessage: "Successfully validated the user authentication",
          errorMessage: "User authentication failed",
          onSuccess: async (response) => {
            // fetch all communities from this location
            setIsLoading(false);
          },
          onError: (error) => {
            console.log("🚀 ~ error:", error);

            setIsLoading(false);
            handleLogout();
          },
        });
      }
    })();
  }, [location]);
  // console.log("response data of booking page", responseData);
  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      {isLoading ? (
        <Loader />
      ) : responseData ? (
        <div className="d-flex flex-column w-100">
          <ExpRestaurantCard data={responseData} />

          {/* Body Section */}
          <div className="card-body my-4">
            {/* Title Section */}
            <div>
              <h5
                className="mb-1"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                {/* {title} */}
                {responseData?.name}
              </h5>
              <p
                className="mb-3"
                style={{ color: "#ff4500", fontSize: "14px" }}
              >
                {responseData?.classification[0]?.title}{" "}
                {responseData?.ageRestriction ? `| {ageLimit}` : ""}
              </p>
            </div>

            {/* Divider */}
            <hr style={{ border: "0.5px solid grey", margin: "10px 0" }} />

            {/* Description Section */}
            <div className="my-3">
              <h6 className="text-muted mb-1">Description</h6>
              <p className="card-text text-muted">
                {responseData?.description || ""}
              </p>
            </div>

            {/* Divider */}
            <hr style={{ border: "0.5px solid grey", margin: "10px 0" }} />

            {/* Availability Section */}
            <div className="d-flex justify-content-between align-items-center my-3">
              <div>
                <h6 className="text-muted mb-1">Availability</h6>
                <p className="mb-0 text-muted">{availability}</p>
              </div>
              <div>
                <h6 className="text-muted mb-1">Timings</h6>
                {/* <p className="mb-0">{timings}</p> */}
                {responseData
                  ? getActivityTimings(responseData)?.multipleHours?.map(
                      (timingsData, index) => {
                        if (timingsData?.open) {
                          return (
                            <React.Fragment key={timingsData?.description}>
                              <div className="">
                                <div>
                                  <span className=" text-muted mr-2">
                                    {moment(timingsData?.openingTime).format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(timingsData?.closingTime).format(
                                      "hh:mm A"
                                    )}
                                  </span>{" "}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        } else return <>Timings Not Available</>;
                      }
                    )
                  : null}
              </div>
              {/* Check Availability Button */}
              <button className="btn">
                <span className="me-2 text-danger">Check Availability</span>
              </button>
            </div>

            {/* Divider */}
            <hr style={{ border: "0.5px solid grey", margin: "10px 0" }} />
          </div>

          <>
            <div className="d-flex flex-column py-8">
              {/* <h1 className="text-3xl font-bold mb-8">Discounted Group Bookings</h1> */}
              <h5
                className="mb-1"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                Discounted Group Bookings
              </h5>

              {/* Responsive Grid for ExperiencePackage components */}
              <div className="container justify-content-start mx-0 p-0 py-3">
                <div className="row gap-3 w-100">
                  {responseData.isPackagesProvided &&
                    responseData.packages.map((packageData) => (
                      <ExperiencePackage
                        data={responseData}
                        packageData={packageData}
                      />
                    ))}
                </div>
              </div>
            </div>

            {/* Blue strip */}
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                height: "2rem",
              }}
              className="my-4"
            >
              <Icons type="blue-strip" />
            </div>

            {/* Restaurant details component */}
            <div className="py-2">
              <h5
                className="mb-1"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                About the Restaurant
              </h5>
              {/* <div className="f18-500 m-2 font-bold">About the Restaurant</div> */}
              {/* <RestaurantCard restaurants={expRestaurantDetails} /> */}
              <AboutRestaurantCard restaurantData={restaurantResponseData} />
            </div>

            {/* Divider */}
            <hr style={{ border: "0.5px solid grey", margin: "10px 0" }} />

            <ExpReviewRating
              experienceId={experienceId}
              data={responseData}
              count={count}
            />

            {/* Terms and contiitions */}
            <div>
              <h5
                className="mb-1"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                Terms & Conditions
              </h5>
              <p className="card-text text-muted">
                {"Corporate Experience single ticket check"}
              </p>
            </div>

            {/*  */}
            <div className="my-3 mb-4">
              <h5
                className="mb-1"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                Refund and Cancellation
              </h5>
              <p className="card-text text-muted mb-3">
                {"Corporate Experience single ticket check"}
              </p>
            </div>
          </>
        </div>
      ) : (
        <>{/* <WebViewComponent accessToken={userAuth.accessToken}/> */}</>
      )}
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      FooterComponent={() => (
        <div className="d-flex justify-content-between align-items-center h-100 ">
          <div className="px-4 d-flex">
            <Icons type="currency" />
            <h5 className="card-title mr-2" style={{ fontWeight: "bold" }}>
              {responseData?.adultPrice}
            </h5>
            <span className="text-muted">{"onwards"}</span>
          </div>
          <div className="mr-4">
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`
                );
              }}
              style={{
                borderRadius: "12px",
                color: "black",
                // border: '1px solid #C4C4C4',
                textTransform: "capitalize",
                backgroundColor: assets.colors.primary,
                color: "white",
              }}
              fullWidth
            >
              Book Now
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default ExperienceBooking;
