import styled from "@emotion/styled";
import {
  Box,
  Button,
  Drawer,
  Rating,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { assets } from "../../assets/assets";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import StarIcon from "@mui/icons-material/Star";

function RatingsAndReviewDrawer({
  open,
  setOpen,
  expData,
  UserData,
  setCount,
  count,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerWidth = isMobile ? "90vw" : "500px";
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?._id) {
      setUserId(userDetails._id);
    } else {
      console.error("User ID not found in localStorage");
    }
  }, []);
  //console.log("User ID:", userId);

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const handleSubmit = async () => {
    let token =
      sessionStorage.getItem("accessToken") ||
      JSON.parse(localStorage.getItem("userDetails"))?.accessToken;

    if (!token) {
      console.error(
        "Access token not found in sessionStorage or localStorage. Please log in again."
      );
      alert("Session expired. Please log in again.");
      return;
    }
    const formData = {
      experienceId: expData?._id,
      expRequestId: "",
      review: review,
      rating: rating,
      user_id: userId,
      isUser: true,
      vendor_id: expData?.vendorId,
      restaurant_id: expData?.restaurantId?._id,
      type: 6,
    };

    try {
      // Await the API call directly
      const response = await executeAPI(
        `${URL.RATINGS_REVIEWS}?isUser=true`,
        "POST",
        formData,
        {
          Authorization: token,
        }
      );

      // if (response) {
      //   console.log("API updated successfully:", response.data);
      // } else {
      //   console.error("API update failed:", response);
      // }
      setCount(++count);
      setReview("");
      setRating(0);
      setOpen(false);
    } catch (error) {
      console.error("Error occurred while executing API:", error);
    }
  };

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: assets.colors.green,
    },
    "& .MuiRating-iconHover": {
      color: assets.colors.green,
    },
  });

  const handleCancel = () => {
    setReview("");
    setRating(0);
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={open}
        // onClose={cancelTrigger}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            // height: '100vh',
            borderRadius: isMobile ? "16px 16px 0 0" : "16px",

            marginBottom: "20px",
          },
        }}
      >
        <Box p={isMobile ? 2 : 3}>
          <div className="f18-700 my-2 text-center">Rate experience</div>
          <Box display="flex" justifyContent="center" my={1}>
            <StyledRating
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              icon={
                <StarIcon
                  fontSize="inherit"
                  style={{
                    fontSize: isMobile ? "2rem" : "2.5rem",
                    color: "#4CAF50",
                  }}
                />
              }
              emptyIcon={
                <StarIcon
                  fontSize="inherit"
                  style={{ fontSize: "2.5rem", color: "#C4C4C4" }}
                />
              }
            />
          </Box>

          <Box className="restaurant_title_card my-2 p-3 shadow-sm rounded-lg">
            <div className="d-flex align-items-center">
              <div className="col-4 flexCenter">
                <Image
                  src={expData?.restaurantId?.logo_url_thumbnails}
                  width={100}
                  height={100}
                  style={{ borderRadius: "8px", marginRight: "15px" }}
                />
              </div>
              <div>
                <div className="f16-700 ">{expData?.restaurantName}</div>
                <div className="f12-500 text-grey">
                  Experience ID: <b className="text-black"> {expData?.expId}</b>
                </div>
                <div className="f14-500 text-grey">
                  Type:{" "}
                  <b className=" f12-500 text-black">{expData?.expType}</b>
                </div>
                <div className="f14-500 text-grey">
                  Date:{" "}
                  <b className="f12-500 text-black">
                    {moment(expData?.startDate).format("MMM DD, yyyy")}
                  </b>
                </div>
              </div>
            </div>
          </Box>
        </Box>
        <Box px={7} py={1}>
          <div className="d-flex align-items-start">
            <Image
              src={expData?.photos[0]}
              width={100}
              height={100}
              style={{ borderRadius: "8px", marginRight: "15px" }}
            />
            <div>
              <div className="f16-700">{expData?.name}</div>
              <p className="f14-500 text-grey">
                {expData?.description?.slice(0, 120)}
              </p>
            </div>
          </div>
        </Box>

        <Box p={3}>
          <div className="f18-700 my-3">Write a Review</div>

          <TextField
            sx={{
              borderRadius: "12px",
              boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            }}
            id="outlined-basic"
            label="write Here"
            variant="outlined"
            fullWidth
            className="customTexfeild"
            multiline
            rows={4}
            value={review}
            onChange={(e) => setReview(e.target.value)}
            // error={paxNumber > maxPaxValue}
            //   helperText={
            //     paxNumber > maxPaxValue
            //       ? `Maximum party size is ${maxPaxValue}  PAX.`
            //       : ''
            //   }
          />
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          my={1}
          px={2}
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => handleCancel()}
            style={{
              borderRadius: "15px",
              color: "black",
              border: "1px solid #C4C4C4",
              textTransform: "capitalize",
            }}
            className="bg-white mr-2"
            fullWidth
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            size="large"
            style={{
              borderRadius: "15px",
              border: "1px solid #C4C4C4",
              color: "white",
              backgroundColor: "#fc5a47",
              textTransform: "capitalize",
            }}
            fullWidth
            disabled={!review}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Box>
      </Drawer>
    </div>
  );
}

export default RatingsAndReviewDrawer;
