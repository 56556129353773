import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { NOTAVAILABLEIMAGE } from "../../common/enums/GlobalEnums";
import ReactPlayer from "react-player";
import AddButton from "./Buttons";
import ComboModal from "../ordering/modals/ComboModal";
import { isEmpty } from "../../common/utility/helperFunc";
import moment from "moment";
import { FLAT_CHARGE_KEYS } from "../../common/enums/OrderEnums";
import { extractTimingData } from "../ONDC/ondcHelperFuction.ts";

const MenuCard = ({
  hide,
  viewType,
  displayType,
  display,
  item,
  storedItems,
  setStoredItems,
  orderSelected,
  restData,
  setPriceAlertModal,
  displayActionCard,
}) => {
  const displayVegNonVegIcon = (type) => {
    if (!isEmpty(type) && type === "COMBO") {
      return item.isVeg;
    } else {
      return item.veg;
    }
  };

  const TruncatedText = ({ text, textLength }) => {
    const maxLength = textLength;
    const truncatedText =
      text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    return (
      <span className="f13-400" style={{ lineHeight: "17px" }}>
        {truncatedText}
      </span>
    );
  };

  const selectedOrderItemPrice = () => {
    let priceObj = {
      listPrice: 0,
      comboPrice: 0,
    };
    if (!isEmpty(orderSelected)) {
      /* TAKE_AWAY */
      if (orderSelected === FLAT_CHARGE_KEYS[0]) {
        priceObj.listPrice = item?.pricing?.takeAway?.listPrice;
        priceObj.comboPrice = item?.pricing?.takeAway?.comboPrice;
      }
      /* CURB_SIDE */
      if (orderSelected === FLAT_CHARGE_KEYS[1]) {
        priceObj.listPrice = item?.pricing?.curbSide?.listPrice;
        priceObj.comboPrice = item?.pricing?.curbSide?.comboPrice;
      }
      /* SKIP_LINE */
      if (orderSelected === FLAT_CHARGE_KEYS[2]) {
        priceObj.listPrice = item?.pricing?.skipTheLine?.listPrice;
        priceObj.comboPrice = item?.pricing?.skipTheLine?.comboPrice;
      }
      /* DINE_IN */
      if (orderSelected === FLAT_CHARGE_KEYS[3]) {
        priceObj.listPrice = item?.pricing?.dineIn?.listPrice;
        priceObj.comboPrice = item?.pricing?.dineIn?.comboPrice;
      }
      /* DELIVERY */
      if (orderSelected === FLAT_CHARGE_KEYS[7]) {
        priceObj.listPrice = item?.pricing?.delivery?.listPrice;
        priceObj.comboPrice = item?.pricing?.delivery?.comboPrice;
      }
    }
    return priceObj;
  };

  const checkDisableFunction = (data) => {
    let currentOrder = "";
    if (orderSelected === "take_away") {
      currentOrder = "takeAway";
    } else if (orderSelected === "curb_side") {
      currentOrder = "curbSide";
    } else if (orderSelected === "dine_in_order") {
      currentOrder = "dineIn";
    } else if (orderSelected === "skip_line") {
      currentOrder = "skipTheLine";
    } else if (orderSelected === "home_delivery") {
      currentOrder = "delivery";
    } else {
      currentOrder = "takeAway";
    }
    const keyAvailable =
      !data?.pricing.hasOwnProperty(currentOrder) ||
      !data?.pricing[currentOrder].hasOwnProperty("comboPrice");
    return keyAvailable;
  };

  return (
    <>
      {viewType === 3 && display ? null : (
        // <div style={{ position: "relative" }}>
        <div className="menu-card relative">
          {item?.spice_level?.value !== undefined && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                gap: "6px",
              }}
            >
              <span className="f16-700">{item?.spice_level?.level ?? 0}</span>
              <Image
                src={require("../../assets/images/IconsandImages/chili.png")}
                alt="Spice Level"
                width="16px"
                height="16px"
              />
              <Image
                src={require("../../assets/images/IconsandImages/heart.png")}
                alt="Favorite"
                width="16px"
                height="16px"
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between px-2 py-2">
            {/* <div className="d-flex align-items-center justify-content-evenly"> */}
            <div className="h-5 flex items-center px-1">
              {item?.primary_tag && item?.primary_tag?.length ? (
                <span
                  className="py-0 px-1 f10-500"
                  style={{
                    border: "0.2px solid #4CAF50",
                    borderRadius: "8px",
                    color: "#4CAF50",
                    backgroundColor: "#4CAF5026",
                  }}
                >
                  {item?.primary_tag[0].title}
                </span>
              ) : null}
            </div>
            {/* </div> */}
          </div>

          {/* Spice Level at Top Right Corner */}
          {/* {item?.spice_level?.value !== undefined && (
              <div
                style={{
                  position: 'absolute',
                  top: '15px',
                  right: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '5px',
                  padding: '5px 8px',
                }}
              >
                <span
                  className="f16-700"
                  style={{
                    marginRight: '5px',
                  }}
                >
                  {item?.spice_level?.level ?? 0}
                </span>
                <Image
                  src={require('../../assets/images/IconsandImages/spicelevel.png')}
                  alt="Spice Level"
                  width="16px"
                  height="16px"
                />
              </div>
            )} */}

          <div className="row m-0">
            <div className="col-4" style={{ textAlign: "center" }}>
              <Image
                src={
                  displayActionCard === "ONDC"
                    ? item?.descriptor?.images?.[0]
                    : item?.images?.[0] || NOTAVAILABLEIMAGE
                }
                alt="Image"
                className="h-24 md:h-32 w-full object-fill border shadow-md"
                style={{
                  borderRadius: "12px",
                  // maxWidth: "100%", // Ensures the image stays within col-4
                  // height: "auto", // Maintains the aspect ratio
                }}
              />
              {item?.rating ? (
                <div
                  className="d-flex justify-content-center align-items-center mt-2"
                  style={{ gap: "8px", maxWidth: "100%" }}
                >
                  <span>
                    <Image
                      src={require("../../assets/images/IconsandImages/warningstar.png")}
                      alt="Rating"
                      width="14px"
                      fluid
                    />
                  </span>
                  <span
                    className="f16-750 opacity- mx-1"
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {item?.rating || 0}
                  </span>
                  {/* {item?.reviews ? (
                      <span
                        className="f16-700 mx-1"
                        style={{ color: '#007bff' }}
                      >
                        ({item?.reviews} Reviews)
                      </span>
                    ) : null} */}
                </div>
              ) : null}
            </div>
            <div className="col-8 d-flex px-2 align-items-center pt-2">
              <div className="col-12">
                <div
                  className="f16-800"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {displayActionCard === "ONDC" ? (
                    <Image
                      src={require(`../../assets/images/IconsandImages/${
                        extractTimingData(item?.tags, "veg_nonveg", "veg") ===
                        "yes"
                          ? "vegindicator.png"
                          : "nonveg_indicator.png"
                      }`)}
                      alt="Image"
                      className="star_rating"
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <Image
                      src={require(`../../assets/images/IconsandImages/${
                        displayVegNonVegIcon(displayType)
                          ? "vegindicator.png"
                          : "nonveg_indicator.png"
                      }`)}
                      alt="Image"
                      className="star_rating"
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <span className="f18-1000">
                    {displayType === "COMBO"
                      ? item?.comboName
                      : displayActionCard === "ONDC"
                      ? item?.descriptor?.name
                      : item?.name}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="d-flex ">
                    <div>
                      <div>
                        {displayType === "COMBO" ? (
                          <TruncatedText
                            text={item?.description}
                            textLength={75}
                          />
                        ) : (
                          <span className="f13-400 mx-1">
                            Gluten Free, Sugar Free, Oil Less +1
                          </span>
                        )}
                      </div>
                      {displayType === "COMBO" ? (
                        <div className="mt-2">
                          {!isEmpty(item?.date_of_availability?.start_date) && (
                            <div className="w-full flex items-center">
                              <span className="f14-700 mx-1">
                                Availability Date:{" "}
                              </span>
                              <span className="f16-700 mx-1">
                                {moment(
                                  item?.date_of_availability?.start_date
                                ).format("DD")}
                                &nbsp;to&nbsp;
                                {moment(
                                  item?.date_of_availability?.end_date
                                ).format("DD MMM")}
                              </span>
                            </div>
                          )}
                          {!isEmpty(item?.time_of_availability?.start_time) && (
                            <div className="w-full flex items-center">
                              <span className="f14-700 mx-1">
                                Availability Time:{" "}
                              </span>
                              <span className="f16-700 mx-1">
                                {moment(
                                  item?.time_of_availability?.start_time,
                                  "HH:mm"
                                ).format("hh:mm A")}
                                &nbsp;to&nbsp;
                                {moment(
                                  item?.time_of_availability?.end_time,
                                  "HH:mm"
                                ).format("hh:mm A")}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <span>
                            <Image
                              src={require("../../assets/images/IconsandImages/timer.png")}
                              alt="Image"
                              width="14px"
                              style={{ marginRight: "10px" }}
                              fluid
                            />
                          </span>
                          <span className="f14-500 mx-1">Prep. Time -</span>
                          <span className="f16-700 mx-1">
                            {item?.prepTime || 0} mins
                          </span>
                        </div>
                      )}
                      {item?.size?.[0]?.calories && (
                        <div className="flex items-center">
                          <span>
                            <Image
                              src={require("../../assets/images/IconsandImages/Vegeterian.png")}
                              alt="Calories"
                              width="12px"
                              style={{ marginRight: "10px" }}
                              fluid
                            />
                          </span>
                          <span className="f16-700 mx-1">
                            {item?.size?.[0]?.calories} Cal
                          </span>
                        </div>
                      )}
                    </div>

                    {item?.spice_level?.value && (
                      <div className="px-1 flex items-center">
                        <span className="f16-700 mx-1">
                          {item?.spice_level?.level}
                        </span>
                        <span>
                          <Image
                            src={require("../../assets/images/IconsandImages/chili.png")}
                            alt="Image"
                            width="12px"
                            fluid
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="f16-700 d-flex align-items-center justify-content-between pt-2">
                  <div className="block md:flex align-items-center">
                    {/* Order Count Section */}
                    {item?.order_count ? (
                      <span className="d-flex align-items-center mr-3">
                        <span className="f16-700 mx-1">
                          {item?.order_count}
                        </span>
                        <span className="f14-500"> Ordered</span>
                      </span>
                    ) : null}

                    {/* View Count Section */}
                    {item?.view_count ? (
                      <span className="d-flex align-items-center">
                        <span className="f16-700 mx-1">{item?.view_count}</span>
                        <span className="f14-500 mx-1">Viewed</span>
                      </span>
                    ) : null}
                  </div>

                  <div className="align-items-center justify-content-end">
                    <div className="d-flex px-4 align-items-center justify-center">
                      {displayType === "COMBO" &&
                        !checkDisableFunction(item) && (
                          <div className="text-lightTextColor line-through mr-2">
                            ₹ {selectedOrderItemPrice().listPrice}
                          </div>
                        )}
                      {displayType === "COMBO" ? (
                        <div>
                          {checkDisableFunction(item)
                            ? "N/A"
                            : `₹ ${selectedOrderItemPrice().comboPrice}`}
                        </div>
                      ) : (
                        <div>₹ {item?.size?.[0]?.price || 0}</div>
                      )}
                    </div>
                    {displayType === "COMBO" ? (
                      <ComboModal
                        item={item}
                        storedItems={storedItems}
                        setStoredItems={setStoredItems}
                        orderSelected={orderSelected}
                        restData={restData}
                        setPriceAlertModal={setPriceAlertModal}
                        disabledAddCombo={checkDisableFunction(item)}
                      />
                    ) : hide ? null : (
                      <AddButton
                        item={item}
                        storedItems={storedItems}
                        setStoredItems={setStoredItems}
                        orderSelected={orderSelected}
                        restData={restData}
                        setPriceAlertModal={setPriceAlertModal}
                      />
                    )}
                    {/* <div>
                        <span className="align-items-center">Size Options</span>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}
    </>
  );
};

export const MenuCardLarge = ({ item }) => {
  return (
    <div>
      <div className="menu-card-large">
        <div className="w-100">
          <Image
            src={(item?.images && item?.images[0]) || NOTAVAILABLEIMAGE}
            alt="Image"
            //width="100%"
            style={{
              borderRadius: "12px",
              maxHeight: "200px",
              maxWidth: "80%",
              height: "auto",
              width: "80%",
              objectFit: "cover",
            }}
            className="text-center"
          />
          <div
            className="d-flex align-items-center justify-content-start px-3"
            style={{ marginTop: "-32px" }}
          >
            <Image
              src={require(`../../assets/images/IconsandImages/${
                item?.veg ? "vegindicator.png" : "nonveg_indicator.png"
              }`)}
              alt="Image"
              width="14px"
              height="14px"
              style={{ backgroundColor: "white" }}
            />
            {/* {item?.primary_tag && item?.primary_tag.length ? (
                <div className="mx-2">
                  <span
                    className="py-0 px-2  f10-500"
                    style={{
                      border: "0.5px solid #4CAF50",
                      borderRadius: "10px",
                      color: "#4CAF50",
                      backgroundColor: "#4CAF5026",
                    }}
                  >
                    {item?.primary_tag[0].title}
                  </span>
                </div>
              ) : null} */}
          </div>
        </div>
        <div>
          <div className="p-3">
            <div className="f16-700 my-2">{item?.name}</div>
            <div className="d-flex my-2">
              <div className="d-flex ">
                <div className="pr-2">
                  <span>
                    <Image
                      src={require("../../assets/images/IconsandImages/warningstar.png")}
                      alt="Image"
                      width="12px"
                      fluid
                    />
                  </span>
                  <span className="f16-700 opacity- mx-1">
                    {item?.rating || 0}
                  </span>
                </div>
                <div className="px-1 ">
                  <span>
                    <Image
                      src={require("../../assets/images/IconsandImages/timer.png")}
                      alt="Image"
                      width="12px"
                      fluid
                    />
                  </span>
                  <span className="f16-700 opacity-50 mx-1">
                    {item?.prepTime || 0} min
                  </span>
                </div>
                {item?.spice_level?.value && (
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require("../../assets/images/IconsandImages/chili.png")}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.spice_level?.level}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="f14-700 my-2 pr-2">
                ₹ {item?.size?.length ? item?.size[0]?.price : "0"}
              </div>
              <div className="px-1 ">
                <span>
                  <Image
                    src={require("../../assets/images/IconsandImages/servesprimary.png")}
                    alt="Image"
                    width="16px"
                    fluid
                  />
                </span>
                <span className="f14-500 opacity-50 mx-1">
                  Serves {item?.size?.length ? item?.size[0]?.pax : "0"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FullcardView = ({ item }) => {
  return (
    <>
      <div>
        <div className="menu-card-large">
          <div
            className="w-100 full-screem-image-menu"
            style={{
              backgroundImage: `url(${item?.images[0] || NOTAVAILABLEIMAGE})`,
              height: "70vh",
              borderRadius: "12px",
              maxWidth: "80%",
              objectFit: "cover",
            }}
          >
            {item?.videos?.length ? (
              // Render the video if a video URL is present
              // <ReactPlayer
              //   url={item.videos[0]}
              //   style={{
              //     width: "100%",
              //     height: "100%",
              //     objectFit: "cover",
              //   }}
              //   controls

              // />
              <video
                src={item.videos[0]}
                alt="Video"
                style={{
                  width: "100vw",
                  height: "100vh",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                //autoPlay
                controls
              />
            ) : (
              // Render the image if no video URL is present
              <img
                src={item?.images[0] || NOTAVAILABLEIMAGE}
                alt="Image"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
          <div>
            <div className="p-3">
              <div className="f16-700 my-2">{item?.name}</div>
              <div className="d-flex my-2">
                <div className="d-flex ">
                  <div className="pr-1">
                    <span>
                      <Image
                        src={require("../../assets/images/IconsandImages/warningstar.png")}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity- mx-1">
                      {item?.rating || 0}
                    </span>
                  </div>
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require("../../assets/images/IconsandImages/timer.png")}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.prepTime || 0} min
                    </span>
                  </div>
                  {item?.spice_level?.value && (
                    <div className="px-1 ">
                      <span className="f14-500 opacity-50 mx-1">
                        {item?.spice_level?.level}
                      </span>
                      <span>
                        <Image
                          src={require("../../assets/images/IconsandImages/chili.png")}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="f14-700 my-2">
                ₹ {item?.size?.length ? item?.size[0]?.price : "0"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuCard;
