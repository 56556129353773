import React, { useEffect, useState } from "react";
import TitleSection from "../../ReusableComponent/TitleSection";
import { json, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../common/api/urls";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { Row, Col, Button, Image, Table, Modal } from "react-bootstrap";
import moment from "moment";
import MenuCard from "../../ReusableComponent/menuCard";
import DottedHr from "../../reusableUiComponents/DottedHr";
import CartMenuCard from "./CartMenuCard";
import {
  calcDiscount,
  calcFinalTotal,
  calcTotalOriginal,
  checkOfferValidOrNot,
} from "../calculations/OrderingCalculations";
import CartBar from "../../ReusableComponent/CartBar";
import { ORDER_TYPES, ORDER_TYPE_NAME } from "../../../common/enums/OrderEnums";
import { ArrowDropDown } from "@mui/icons-material";
import { IconButton, TextField, Drawer } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UserRegistration from "../../ReusableComponent/UserRegistration";
import DineInDrawer from "../modals/DineInDrawer";
import { isEmpty } from "../../../common/utility/helperFunc";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EastIcon from "@mui/icons-material/East";
import OffersOrdering from "./OffersOrdering";
import { assets } from "../../../assets/assets";
import OfferSuccessPopup from "./OfferSuccessPopup";
import ScreenTemplate from "../../../components/ScreenTemplate";
import MainHeader from "../../../components/headers/Mainheader";
import MainSideBar from "../../../components/MainsideBar";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import { useSelector } from "react-redux";
import { useLocation as use_location } from "react-router-dom";

function CartPage() {
  const navigate = useNavigate();
  const propsData = use_location();
  const { ID } = useParams();

  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const AccessToken =
    useSelector((state) => state.user.userDetails.accessToken) ||
    sessionStorage.getItem("accessToken") ||
    JSON.parse(localStorage.getItem("userDetails"))?.accessToken;

  const UserData = useSelector((state) => state.user.userDetails);

  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    restId: ID,
    offerModal: false,
    orderData: {},
    offerSuccessPopup: false,
    isApply: false,
    isInput: false,
    prevOfferId: "",
    couponCode: "",
    offerErrorMsg: "",
    unavailableItemModal: false,
    unavailableItemsData: [],
    checkDeliveryAvailable: false,
  });

  const {
    isLoading,
    restId,
    offerModal,
    offerSuccessPopup,
    isApply,
    isInput,
    prevOfferId,
    couponCode,
    offerErrorMsg,
    unavailableItemModal,
    unavailableItemsData,
    checkDeliveryAvailable,
  } = state;

  const [storedItems, setStoredItems] = useState(
    JSON.parse(sessionStorage.getItem("cartItems")) || []
  );
  const [OrderData, setOrderData] = useState(
    JSON.parse(sessionStorage.getItem("order_details")) || []
  );

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [dineInskip, setdineInSkip] = useState(false);
  const [showTax, setShowtax] = useState(false);
  const [showsurCharges, SetShowcharges] = useState(false);

  const [notes, setNotes] = useState(OrderData?.notes || "");

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    ordercancel,
    orderresponseData,
    ordererror,
    orderloaded,
    orderreset,
    orderexecuteAPI,
  ] = useAxios();
  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    offercancel,
    offeresponseData,
    // eslint-disable-next-line
    offererror,
    // eslint-disable-next-line
    offerloaded,
    // eslint-disable-next-line
    offerreset,
    offerexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    offerPostcancel,
    offerPostesponseData,
    // eslint-disable-next-line
    offerPosterror,
    // eslint-disable-next-line
    offerPostloaded,
    // eslint-disable-next-line
    offerPostreset,
    offerPostexecuteAPI,
  ] = useAxios();

  const orderType = sessionStorage.getItem("cartOrderType");

  const subscriptionData = state?.restData?.subscription;

  useEffect(() => {
    if (
      orderType === "take_away" ||
      orderType === "skip_line" ||
      orderType === "curb_side" ||
      orderType === "dine_in" ||
      orderType === "home_delivery"
    ) {
      const data = {
        user_details: {
          first_name: UserData?.first_name,
          last_name: UserData?.last_name,
          country_code: UserData?.country_code,
          mobile_number: UserData?.mobile_number,
          user_id: UserData?._id,
          email: UserData?.email,
        },
        order_type: ORDER_TYPES.indexOf(orderType),
        orderSelected: orderType,
        now: true,
        nowDate: new Date(),
        later_date: "",
        later_time: "",
        actual_date: "",
        later_formDated_date: "",
        delivery_partner: 1,
        dine_in_details: {
          table_number: "",
        },
        totalDinePax: "",
        curb_side_details: {
          vehical_type: 4,
          vehical_type_value: "",
          number: "",
          make: "",
          model: "",
          year: "",
          color: "",
          pickUpPoint: null,
        },
        user_location: {
          lat: !isEmpty(location)
            ? location?.lat
            : UserData?.lastLocation?.coordinates[1],
          long: !isEmpty(location)
            ? location?.lng
            : UserData?.lastLocation?.coordinates[0],
        },
      };
      setOrderData(data);
      sessionStorage.setItem("order_details", JSON.stringify(data));
    }
  }, [UserData]);

  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        "GET",
        null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
    }
    if (responseData) {
      offerexecuteAPI(
        `${URL.USEROFFERS}/?vendorId=${responseData.vendorId}&cart=true`,
        "GET",
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (offerPostesponseData) {
      const formData = {};
      formData.isPercentage = offerPostesponseData?.isPercentage;
      formData.discountAmount = offerPostesponseData?.discount;
      formData.coupon_code = offerPostesponseData?.coupon_code;
      formData.type = offerPostesponseData?.type;
      formData.deliveryMaxDiscount = offerPostesponseData?.deliveryMaxDiscount;
      formData.deliveryMinOrder = offerPostesponseData?.deliveryMinOrder;
      formData.isDelivery = offerPostesponseData?.isDelivery;
      formData.isOfferApplied = true;
      formData.offerId = true;
      onOfferApplySuccess(formData);
    }
  }, [offerPostesponseData]);

  let summary = calcFinalTotal(
    storedItems,
    OrderData,
    offeresponseData,
    subscriptionData
  );

  const naviageViewMenu = () => {
    navigate(`/restaurant/${ID}/viewmenu`);
  };

  const intialOrderCall = () => {
    const formData = OrderData;
    // External Wallet Validation
    formData.order_items = [];
    const exisitingCartData = storedItems;
    const totalBaseAmt = calcTotalOriginal(exisitingCartData);

    formData.base_amount = totalBaseAmt;
    formData.user_details = {
      first_name: UserData?.first_name,
      last_name: UserData?.last_name,
      country_code: UserData?.country_code,
      mobile_number: UserData?.mobile_number,
      user_id: UserData?._id,
      email: UserData?.email,
    };

    if (formData.order_type === 7) {
      let subscription = subscriptionData;
      let minAmount = 0;
      if (subscription.casual_dining) {
        minAmount =
          subscription?.casual_dining_status?.ordering?.home_delivery
            ?.minimum_order_value || 0;
      } else if (subscription.fast_food_dining) {
        minAmount =
          subscription?.fast_food_dining?.ordering?.home_delivery
            ?.minimum_order_value || 0;
      } else if (subscription.multi_service_business) {
        minAmount =
          subscription?.multi_service_business?.ordering?.home_delivery
            ?.minimum_order_value || 0;
      }
      if (formData.base_amount < minAmount) {
        this.setState({ ...this.state, minAmountModal: true });
        return;
      }
    }
    //formData.total_amount = parseFloat(totalAmount) + parseFloat(totalCharges);

    formData.gstAmount = summary?.taxes?.gst;
    formData.surCharges = summary?.taxes?.surCharges;
    formData.total_amount = Number(summary?.total);
    formData.payment_status = 0;

    for (let i = 0; i < exisitingCartData.length; i++) {
      formData.order_items.push(exisitingCartData[i]);
    }

    formData.tip = {
      value: formData?.tip?.value || 0,
      amount: formData?.tip?.amount,
    };
    formData.notes = formData?.notes || "";
    formData.donation = {
      amount: formData?.donation?.amount || 0,
      value: formData?.donation?.amount || 0,
    };
    //formData.discount = { amount: 0, percentage: 0 };
    formData.deliveryCharges = OrderData?.deliveryCharges;

    //USER DETAILS UPDATE //
    // formData.user_details = {};
    // formData.user_details.user_id = UserData?._id;
    formData.token = UserData?.FCMtoken;
    formData.user_id = UserData?._id;
    formData.vendor_id = state?.restData?.vendor_id?._id;
    formData.restaurant_id = state?.restData?._id;
    formData.actual_date = moment().format("YYYY-MM-DD");
    formData.pointOfEntry = "WEBAPP";
    formData.user_location = {
      lat: !isEmpty(location)
        ? location?.lat
        : UserData?.lastLocation?.coordinates[1],
      long: !isEmpty(location)
        ? location?.lng
        : UserData?.lastLocation?.coordinates[0],
    };
    // formData.restaurantDetails
    // if (OrderData.isOfferApplied) {
    //   formData.discount.amount = calcDiscount(
    //     this.props.cart_data,
    //     OrderData,
    //     this.props.offerData
    //   );
    //   if (OrderData.isPercentage) {
    //     formData.discount.percentage = OrderData.discountAmount;
    //   }
    // }

    formData.deliveryDiscount = 0;
    const order_id = Math.floor(100000 + Math.random() * 900000);
    formData["order_id"] = order_id;
    sessionStorage.setItem("orderPostData", JSON.stringify(formData));

    if (propsData?.state?.fromOndc) {
      navigate(`/ondc/${ID}/checkout/${order_id}`, {
        state: {
          orderData: { order_id },
          from: propsData?.state?.from,
          fromOndc: true,
        },
      });
    } else {
      navigate(`/restaurant/${ID}/food/checkout/${order_id}`, {
        state: {
          orderData: { order_id },
          from: propsData?.state?.from,
        },
      });
    }
    // orderexecuteAPI(`${URL.USER_ORDER}`, 'POST', formData, {
    //   Authorization: sessionStorage.getItem('accessToken'),
    // });
  };
  useEffect(() => {
    if (responseData) {
      //console.log("Restaurant Details Response:", responseData);
      offerexecuteAPI(
        `${URL.USEROFFERS}/?vendorId=${responseData.vendorId}&cart=true`,
        "GET",
        null
      );
    }
  }, [responseData]);
  // const submitNotes = () => {
  //   let newOrderData = {
  //     ...OrderData,
  //     notes: notes,
  //   };
  //   sessionStorage.setItem("order_details", JSON.stringify(newOrderData));
  //   console.log(
  //     "dat",
  //     sessionStorage.setItem("order_details", JSON.stringify(newOrderData))
  //   );
  // };
  const submitNotes = () => {
    let newOrderData = {
      ...OrderData,
      notes: notes,
    };
    //localStorage.setItem("orderDetails", JSON.stringify(newOrderData));
    sessionStorage.setItem("order_details", JSON.stringify(newOrderData));
    setNotes("");
  };

  useEffect(() => {
    const storedOrderDetails = JSON.parse(localStorage.getItem("orderDetails"));
    //console.log("Stored Order Details:", storedOrderDetails);
    if (storedOrderDetails?.notes) {
      //setNotes(storedOrderDetails.notes);
    } else {
      setNotes("");
      //console.error("Order details not found in localStorage");
    }
  }, []);

  //  offer releated functions //

  const onClickRemoveApplyPromotion = (orderData) => {
    let orderInfoData = orderData;
    orderInfoData.coupon_code = "";
    orderInfoData.offerId = "";
    orderInfoData.discountAmount = 0;
    orderInfoData.isOfferApplied = false;
    orderInfoData.isPercentage = false;
    orderInfoData.discount.amount = 0;
    let summary = calcFinalTotal(
      storedItems,
      orderInfoData,
      offeresponseData,
      subscriptionData
    );
    orderInfoData.gstAmount = summary?.taxes?.gst;
    orderInfoData.surCharges = summary?.taxes?.surCharges;
    orderInfoData.total_amount = +summary?.total;

    setState({
      ...state,
      orderData: orderInfoData,
      offerModal: false,
      isInput: false,
      offerSuccessPopup: false,
      isApply: false,
      couponCode: "",
      offerErrorMsg: "",
    });
    // dispatch({
    //   type: APPLY_OFFER_DATA,
    //   payload: {},
    // });
    setOrderData(orderInfoData);
    sessionStorage.setItem("order_details", JSON.stringify(orderInfoData));
  };

  const onApplyOffers = (textInput, offerObj) => {
    if (textInput === "INPUT") {
      let validateOffer = checkOfferValidOrNot(
        offeresponseData,
        couponCode,
        OrderData
      );

      if (validateOffer.length > 0) {
        setState({
          ...state,
          offerErrorMsg: "",
        });
        checkOffer(validateOffer[0], true);
      } else {
        setState({
          ...state,
          isInput: true,
          prevOfferId: "",
          offerErrorMsg:
            "Coupon code is invalid! Please try with another coupon code",
          couponCode: "",
        });
      }
    } else {
      checkOffer(offerObj, false);
    }
  };

  const checkOffer = (data, isInput) => {
    let cartAmount = calcTotalOriginal(storedItems);

    if (isInput === true) {
      if (+cartAmount >= +data?.minimum_order_applied) {
        if (data?.maximum_order_applied !== null) {
          if (+cartAmount <= +data?.maximum_order_applied) {
            setState({
              ...state,
              couponCode: data?.coupon_code,
              isApply: false,
            });
            applyPromoCode(data, false, isInput);
          } else {
            setState({
              ...state,
              isInput: true,
              prevOfferId: "",
              offerErrorMsg: `Sorry! You have exceeded the maximum order value limit for this offer.Please add items worth upto ₹ ${Number(
                data?.isDelivery
                  ? data?.deliveryMinOrder
                  : data?.maximum_order_applied
              )} to avail this offer.`,
              couponCode: "",
              isApply: false,
            });
          }
        } else {
          setState({
            ...state,
            couponCode: data?.coupon_code,
            isApply: false,
          });
          applyPromoCode(data, false, isInput);
        }
      } else {
        setState({
          ...state,
          isInput: true,
          prevOfferId: "",
          offerErrorMsg: `Order Value is less than the minimum order value. Please add items worth Rs.${
            Number(
              data?.isDelivery
                ? data?.deliveryMinOrder
                : data?.minimum_order_applied
            ) - Number(cartAmount)
          } more to the cart and re-apply the offer.`,
          couponCode: "",
          isApply: false,
        });
      }
    } else {
      if (+cartAmount >= +data?.minimum_order_applied) {
        if (data?.maximum_order_applied !== null) {
          if (+cartAmount <= +data?.maximum_order_applied) {
            setState({
              ...state,
              couponCode: data?.coupon_code,
              isApply: false,
            });
            applyPromoCode(data, false, isInput);
          } else {
            setState({
              ...state,
              isInput: false,
              prevOfferId: data?._id,
              offerErrorMsg: `Sorry! You have exceeded the maximum order value limit for this offer.Please add items worth upto ₹ ${Number(
                data?.isDelivery
                  ? data?.deliveryMinOrder
                  : data?.maximum_order_applied
              )} to avail this offer.`,
              couponCode: "",
              isApply: false,
            });
          }
        } else {
          setState({
            ...state,
            offerErrorMsg: data?.coupon_code,
            isApply: false,
          });
          applyPromoCode(data, false, isInput);
        }
      } else {
        setState({
          ...state,
          isInput: false,
          prevOfferId: data?._id,
          offerErrorMsg: `Add items worth ₹ ${
            Number(
              data?.isDelivery
                ? data?.deliveryMinOrder
                : data?.minimum_order_applied
            ) - Number(cartAmount)
          }  more to apply this offer.`,
          couponCode: "",
          isApply: false,
        });
      }
    }
  };

  const applyPromoCode = async (offerInfo, isApply, isInput) => {
    let cartAmount = calcTotalOriginal(storedItems);
    let orderCartData = OrderData;

    let queryData = {
      vendor_id:
        offerInfo?.pointOfEntry === "ADMIN"
          ? offerInfo?.vendor_id
          : state?.restData?.vendorId,
      coupon_code: offerInfo?.coupon_code,
      user_id: UserData?._id,
      order_amount: calcTotalOriginal(storedItems),
      offerId: offerInfo?._id,
    };
    if (offerInfo?.isDelivery) {
      if (+offerInfo?.deliveryMinOrder <= +cartAmount) {
        if (
          offerInfo?.maximum_usage_limit &&
          offerInfo?.offerUsed < offerInfo?.maximum_usage_limit
        ) {
          setState({
            ...state,
            // offerModal: false,
            offerErrorMsg: "",
            prevOfferId: offerInfo?._id,
            couponCode: offerInfo?.coupon_code,
            isApply: true,
          });
          // dispatch({
          //   type: APPLY_OFFER_DATA,
          //   payload: offerInfo,
          // });
          // dispatch(
          //   apply_offer_code(queryData, onOfferApplySuccess, (err) =>
          //     onOfferError(err)
          //   )
          // );

          offerPostexecuteAPI(
            `${URL.USEROFFERS}/?vendorId=${state?.restData?.vendorId}&cart=true`,
            "POST",
            queryData
          );
        } else {
          setState({
            ...state,
            offerErrorMsg: "Offer has reached to maximum limit.",
            isApply: false,
            isInput: isInput,
            prevOfferId: offerInfo?._id,
          });
        }
      } else {
        setState({
          ...state,
          offerErrorMsg:
            "This offer is not applicable. Please try another offer.",
          isApply: false,
          isInput: isInput,
          prevOfferId: offerInfo?._id,
        });
      }
    } else {
      if (
        +offerInfo?.minimum_order_applied <= +cartAmount &&
        +offerInfo?.maximum_order_applied >= +cartAmount
      ) {
        if (
          offerInfo?.maximum_usage_limit &&
          offerInfo?.offerUsed < offerInfo?.maximum_usage_limit
        ) {
          setState({
            ...state,
            // offerModal: false,
            offerErrorMsg: "",
            prevOfferId: offerInfo?._id,
            couponCode: offerInfo?.coupon_code,
            isApply: true,
          });
          // dispatch({
          //   type: APPLY_OFFER_DATA,
          //   payload: offerInfo,
          // });
          // dispatch(
          //   apply_offer_code(queryData, onOfferApplySuccess, (err) =>
          //     onOfferError(err)
          //   )
          // );

          offerPostexecuteAPI(
            `${URL.USEROFFERS}/?vendorId=${state?.restData?.vendorId}&cart=true`,
            "POST",
            queryData
          );
        } else {
          setState({
            ...state,
            offerErrorMsg: "Offer has reached to maximum limit.",
            isApply: false,
            isInput: isInput,
            prevOfferId: offerInfo?._id,
          });
        }
      } else {
        setState({
          ...state,
          offerErrorMsg:
            "This offer is not applicable. Please try another offer.",
          isApply: false,
          isInput: isInput,
          prevOfferId: offerInfo?._id,
        });
      }
    }
  };

  const onOfferApplySuccess = async (data) => {
    let orderData = OrderData;
    orderData.discount = {};
    orderData.isPercentage = data?.isPercentage;
    orderData.coupon_code = data?.coupon_code;
    orderData.isOfferApplied = data?.isOfferApplied;
    orderData.discountAmount = data?.discountAmount;
    orderData.discount.percentage = data?.isPercentage ? 0 : 1;
    orderData.offerId = data?.offerId;
    orderData.deliveryMaxDiscount = data?.deliveryMaxDiscount;
    orderData.deliveryMinOrder = data?.deliveryMinOrder;
    orderData.isDelivery = data?.isDelivery;
    orderData.type = data?.type;
    /*****************/
    setOrderData(orderData);
    await sessionStorage.setItem("order_details", JSON.stringify(orderData));
    // dispatch({
    //   type: CREATE_ORDER_INFO,
    //   payload: parsed,
    // });
    successMessage();
  };

  const successMessage = async () => {
    // let orderData = await AsyncStorage.getItem('order_Info');

    let parsed = OrderData;
    parsed.discount.amount = +calcDiscount(
      storedItems,
      parsed,
      offeresponseData
    );

    let summary = calcFinalTotal(
      storedItems,
      parsed,
      offeresponseData,
      subscriptionData
    );
    parsed.gstAmount = summary?.taxes?.gst;
    parsed.surCharges = summary?.taxes?.surCharges;
    parsed.total_amount = +summary?.total;
    setState({
      ...state,
      orderData: parsed,
      offerModal: false,
      isInput: false,
      offerSuccessPopup: !offerSuccessPopup,
    });
    setOrderData(parsed);
    sessionStorage.setItem("order_details", JSON.stringify(parsed));
    // dispatch({
    //   type: CREATE_ORDER_INFO,
    //   payload: parsed,
    // });
  };

  // const logincallBack = (data) => {
  //   setUserData(data);
  // };

  useEffect(() => {
    if (OrderData?.coupon_code && OrderData?.isOfferApplied) {
      let orderDataExits = JSON.parse(JSON.stringify(OrderData));
      let cartAmount = calcTotalOriginal(storedItems);
      let appliedOffer = offeresponseData?.find(
        (e) => e.coupon_code === OrderData?.coupon_code
      );
      if (orderDataExits?.isDelivery && orderDataExits?.isOfferApplied) {
        if (+cartAmount < +orderDataExits?.deliveryMinOrder) {
          onClickRemoveApplyPromotion(orderDataExits);
        } else {
          orderDataExits.discount.amount = +calcDiscount(
            storedItems,
            orderDataExits,
            offeresponseData
          );
          let summary = calcFinalTotal(
            storedItems,
            orderDataExits,
            offeresponseData,
            subscriptionData
          );
          orderDataExits.base_amount = +cartAmount;
          orderDataExits.gstAmount = summary?.taxes?.gst;
          orderDataExits.surCharges = summary?.taxes?.surCharges;
          orderDataExits.total_amount = +summary?.total;
          setOrderData(orderDataExits);

          sessionStorage.setItem(
            "order_details",
            JSON.stringify(orderDataExits)
          );
        }
      } else if (
        !orderDataExits?.isDelivery &&
        orderDataExits?.isOfferApplied
      ) {
        if (+cartAmount < +appliedOffer?.minimum_order_applied) {
          onClickRemoveApplyPromotion(orderDataExits);
        } else {
          orderDataExits.discount.amount = +calcDiscount(
            storedItems,
            orderDataExits,
            offeresponseData
          );
          let summary = calcFinalTotal(
            storedItems,
            orderDataExits,
            offeresponseData,
            subscriptionData
          );
          orderDataExits.base_amount = +cartAmount;
          orderDataExits.gstAmount = summary?.taxes?.gst;
          orderDataExits.surCharges = summary?.taxes?.surCharges;
          orderDataExits.total_amount = summary?.total;
          setOrderData(orderDataExits);

          sessionStorage.setItem(
            "order_details",
            JSON.stringify(orderDataExits)
          );
        }
      }

      // let parsed = OrderData;
      // parsed.discount.amount = +calcDiscount(
      //   storedItems,
      //   parsed,
      //   offeresponseData
      // );
      // let summary = calcFinalTotal(
      //   storedItems,
      //   parsed,
      //   offeresponseData,
      //   subscriptionData
      // );
      // parsed.gstAmount = summary?.taxes?.gst;
      // parsed.surCharges = summary?.taxes?.surCharges;
      // parsed.total_amount = +summary?.total;
    }
  }, [storedItems, offeresponseData]);

  const content = (
    <div>
      {/* <TitleSection
        bg={'#FFFFFF'}
        title={state?.restData?.restaurant_name}
        backRoute={`/restaurant/${ID}/viewmenu`}
        className={'box-shadow-down'}
        resticon={state?.restData?.logo_url_thumbnails}
      /> */}
      <div>
        <div
          className="f14-700 p-2 py-3"
          style={{ backgroundColor: "#80808014" }}
        >
          <span className="text-grey"> Order References : </span>{" "}
          {ORDER_TYPE_NAME[ORDER_TYPES.indexOf(orderType)]}
        </div>
      </div>

      <div className="row mx-0  py-2" style={{ backgroundColor: "#80808014" }}>
        <div className="col-12 d-flex ">
          <div className="text-center col-4 col-md-1">
            <Image
              src={require("../../../assets/images/IconsandImages/calexp.png")}
              alt="icon"
              width="25px"
              height="25x"
              style={{ maxWidth: "100%" }}
            />
            <div className="f14-700">{moment().format("DD MMM YYYY")}</div>
          </div>
          <div className="text-center col-4 col-md-1">
            <Image
              src={require("../../../assets/images/IconsandImages/clockexp2.png")}
              alt="icon"
              width="25px"
              height="25x"
              style={{ maxWidth: "100%" }}
            />
            <div className="f14-700">{moment().format("hh:mm A")}</div>
          </div>
          {orderType === "dine_in" && (
            <div className="col-4 col-md-1">
              <div className="f14-700">
                {" "}
                Table No : {OrderData?.dine_in_details?.table_number || "N/A"}
              </div>
              <div className="f14-700">
                {" "}
                Pax : {OrderData?.totalDinePax || 0}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div style={{ marginTop: '-10px' }}>
        <Image
          src={require('../../../assets/images/ExperiencePageAssets/design_2.png')}
          width={'100%'}
          className="m-0 p-0"
        />
      </div> */}
      <div>
        <div className="d-flex justify-content-between p-3">
          <div className="f18-700">Order Details</div>
          <div
            className="f14-500"
            style={{ color: "#2196f3" }}
            onClick={naviageViewMenu}
          >
            Add More Items
          </div>
        </div>
      </div>
      <Row>
        {storedItems?.map((items, index) => {
          return (
            <Col sm={12} md={6} key={items?._id}>
              <CartMenuCard
                index={index}
                item={items.item}
                storedItems={storedItems}
                setStoredItems={setStoredItems}
                fullItemData={items}
              />
            </Col>
          );
        })}
      </Row>
      {storedItems.length ? (
        <>
          <div className="p-2">
            <div className="f14-700 d-flex align-items-center">
              {" "}
              <DescriptionOutlinedIcon mx={4} />
              Write Notes For Kitchen
            </div>
            <div className="my-2">
              <TextField
                id="outlined-basic"
                placeholder="I want less sugar."
                variant="standard"
                size="small"
                className="customTexfeild"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                sx={{ border: "none" }}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="send" onClick={() => submitNotes()}>
                      <EastIcon
                        sx={{
                          color: "whitesmoke",
                          backgroundColor: "#96ade1",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>
          </div>
          <OffersOrdering
            offers={offeresponseData}
            orderType={orderType}
            isInput={isInput}
            prevOfferId={prevOfferId}
            cancelTrigger={() =>
              setState({
                ...state,
                offerModal: false,
                isInput: false,
              })
            }
            promoCode={couponCode}
            discountMessage={offerErrorMsg}
            onApplyClickHandler={(actionType, data) =>
              onApplyOffers(actionType, data)
            }
            onChangeText={(text) => {
              setState({
                ...state,
                couponCode: text.toUpperCase(),
              });
            }}
            isVisible={offerModal}
            // showModal={() =>
            //   setState({
            //     ...state,
            //     offerModal: true,
            //   })
            // }
            showModal={() => {
              //console.log("Opening offers modal...");
              setState((prevState) => ({
                ...prevState,
                offerModal: true,
              }));
            }}
            orderData={OrderData}
            onClickRemoveApplyPromotion={onClickRemoveApplyPromotion}
            showLoginModal={setshowOtpModal}
          />
          <div className="pb-5">
            <div className="p-3 f14-700">Order Summary</div>
            <DottedHr />
            <div className="p-3 f14-700">
              <div className="flex-between my-2">
                <div>Original Item Value</div>
                <div>₹{calcTotalOriginal(storedItems)}</div>
              </div>
              {!isEmpty(OrderData?.coupon_code) &&
              OrderData?.coupon_code.length > 0 &&
              OrderData?.discount?.amount > 0 ? (
                <div className="flex-between my-2">
                  <div>
                    Discount{" "}
                    <span style={{ color: assets.colors.primary }}>
                      ({OrderData?.coupon_code})
                    </span>
                  </div>
                  <div
                    style={{ color: assets.colors.primary }}
                  >{`- ₹ ${OrderData?.discount?.amount.toFixed(2)}`}</div>
                </div>
              ) : null}
              <div className="my-2">
                <div className="flex-between">
                  <button onClick={() => setShowtax(!showTax)}>
                    {" "}
                    {showTax ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                    Taxes
                  </button>
                  <div>
                    ₹{parseFloat(summary?.taxes?.gstTotal).toFixed(2) || 0}
                  </div>
                </div>
                {showTax && (
                  <div className="pl-4 py-1 f12-500">
                    {summary?.taxes?.gstTotal
                      ? Object.entries(summary?.taxes?.gst).map(
                          ([keyValue, value]) => (
                            <div
                              className="flex-between"
                              key={keyValue + value}
                            >
                              <div>{keyValue}</div>
                              <div>₹{value.toFixed(2)}</div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                )}
              </div>
              <div className="my-2">
                <div className="flex-between">
                  <button onClick={() => SetShowcharges(!showsurCharges)}>
                    {" "}
                    {showsurCharges ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                    Surcharges
                  </button>
                  <div>
                    ₹{parseFloat(summary?.taxes?.surTotal).toFixed(2) || 0}
                  </div>
                </div>
                {showsurCharges && (
                  <div className="pl-4 py-1 f14-500">
                    {summary?.taxes?.surTotal
                      ? Object.entries(summary?.taxes?.surCharges).map(
                          ([keyName, value]) => (
                            <div className="flex-between" key={keyName + value}>
                              <div>{keyName}</div>
                              <div>₹{value}</div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                )}
              </div>
              <AddTip OrderData={OrderData} setOrderData={setOrderData} />
              <DonateUdbhav OrderData={OrderData} setOrderData={setOrderData} />
              <hr />
              <div className="flex-between f16-700">
                <div>Grand Total</div>
                <div>₹{summary?.total || 0}</div>
              </div>
              <hr />
            </div>
          </div>
        </>
      ) : (
        <div className="p-2 text-center">
          <div style={{ height: "30vh" }}></div>
          <div className="f18-700 mt-4">Your Cart is empty</div>
          <div className="f16-500 text-grey my-4">
            Please add something from menu to enjoy the delicious food and our
            services
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: "100",
        }}
      >
        <div className="row w-100 m-0">
          <div className="col-10 p-0">
            <div className=" w-100">
              {/* <DownloadBar
                    text="Online ordering is only available on Amealio app"
                    buttonText={'Download App'}
                    title=" "
                    noImage
                  /> */}
              {storedItems.length ? (
                <CartBar
                  intialOrderCall={intialOrderCall}
                  setshowOtpModal={setshowOtpModal}
                  setdineInSkip={setdineInSkip}
                  summary={summary}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* {showOtpModal ? (
        <UserRegistration
          open={showOtpModal}
          setshowOtpModal={(val) => setshowOtpModal(val)}
          //  setshowDineInModal={(val) => setshowDineInModal(val)}
          logincallback={(val) => logincallBack(val)}
        />
      ) : null} */}
      <DineInDrawer open={dineInskip} setOpen={setdineInSkip} />
      {!offerModal && offerSuccessPopup ? (
        <OfferSuccessPopup
          isVisible={offerSuccessPopup}
          promoCode={OrderData?.coupon_code}
          cancelTrigger={() => {
            setState({ ...state, offerSuccessPopup: !offerSuccessPopup });
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
}

function AddTip({ OrderData, setOrderData }) {
  const [showTipDrawer, setShowTipDrawer] = useState(false);
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(OrderData?.tip?.amount || 0);
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const handleAddTip = (e) => {
    setTipAmount(e.target.value);
    setShowTips(false);
  };
  const handleTipLearnMore = () => {
    setShowTipDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    setShowTips(true);
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    setTipAmount(customAmount);
    setShowTips(false);
    setcustom(false);
  };

  useEffect(() => {
    let numericTiPAmount = parseFloat(tiPAmount);
    let newOrderData = { ...OrderData, tip: { amount: numericTiPAmount } };
    sessionStorage.setItem("order_details", JSON.stringify(newOrderData));
    setOrderData(newOrderData);
  }, [tiPAmount]);
  return (
    <>
      <div className="add-tip">
        <div>
          Add Tip{" "}
          <span
            className="f12-500 ml-2"
            style={{ color: "#2196f3", cursor: "pointer" }}
            onClick={handleTipLearnMore}
          >
            Learn More
          </span>
        </div>
        <div className="primary-color f12-700" onClick={handleChange}>
          Change Amount
        </div>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Tip Modal */}
      <Drawer
        anchor="bottom"
        open={showTipDrawer}
        onClose={() => setShowTipDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div
          style={{
            padding: "20px",

            borderRadius: "16px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}> Add Tip</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/addtipNEW.png")}
              alt="Udbhav"
              style={{ maxWidth: "300px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.6" }}>
            A Customer may at it's sole discretion add a certain amount as "Tip"
            for the services provided through the Amealio Platform.Any amount
            recieved towards Tips shall be non-refundable except in the
            following circumstances: A. Order is auto-cancelled before the
            acceptance of the order bythe Merchant. b. If the Order is cancelled
            by the Merchant due to such as non-availability of food items
            ordered by the customer, non-availability of the service, or any
            other reasons attributable to the Merchant. However, in case of
            partial cncellation or modifications in the order/ Booking, the
            amount contributed towards Tips shall be non-refundableAmealio
            reserves the right, at its sole discretion, to refuse the refund of
            the amount pay towards Tips for any reason what so ever, without
            assigning any reason to the customer and the customer accepts and
            undertakes to not raise any dispute on the same.
          </p>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={() => {
              setShowTipDrawer(false);
              setShowTips(true);
            }}
          >
            Add Tip
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export function DonateUdbhav({ OrderData, setOrderData }) {
  const [showDonatePopup, setShowDonatePopup] = useState(false);
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(OrderData?.donation?.amount || 0);
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const handleAddTip = (e) => {
    setTipAmount(e.target.value);
    setShowTips(false);
  };

  const handleDonateLearnMore = () => {
    setShowDonateDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    setShowTips(true);
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    setTipAmount(customAmount);
    setShowTips(false);
    setcustom(false);
  };

  useEffect(() => {
    let numericTiPAmount = parseFloat(tiPAmount);
    let newOrderData = { ...OrderData, donation: { amount: numericTiPAmount } };
    sessionStorage.setItem("order_details", JSON.stringify(newOrderData));
    setOrderData(newOrderData);
  }, [tiPAmount]);
  return (
    <>
      <div className="add-tip">
        <div>
          Donate to Udbhav{" "}
          {/* <span className="f12-500" style={{ color: "#2196f3" }}>
            Learn More
          </span> */}
          <span
            className="f12-500"
            style={{ color: "#2196f3", cursor: "pointer", fontWeight: "500" }}
            onClick={handleDonateLearnMore}
          >
            Learn More
          </span>
        </div>
        <div
          className="primary-color f12-700"
          onClick={handleChange}
          style={{ marginRight: "70px" }}
        >
          Change Amount
        </div>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Udbhav Modal */}
      <Drawer
        anchor="bottom"
        open={showDonateDrawer}
        onClose={() => setShowDonateDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2 style={{ fontWeight: "bold" }}>Donate To Udbhav</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/udbhav.png")}
              alt="Udbhav"
              style={{ maxWidth: "150px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "16px", color: "#333", lineHeight: "1.6" }}>
            Udbhav is a foundation focused on providing resources to the
            underprivileged sectors while creating a lasting impact that
            empowers communities. We cater to families in need of bare
            necessities and motivate children to study by developing specialized
            programs to improve their quality of life. Driven by the youth,
            Udbhav willingly partners up with similar organizations to help
            create change. We believe in creating a sustainable world by
            participating in drives that address environmental concerns. A
            recent partnership with the Akshaya Patra organization led to 500
            food packets delivered to the families and people most affected by
            the pandemic. A host of partnerships and events that give back to
            society are on the horizon. Be a part of this and help us to change
            the world!
          </p>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "25px",
              textTransform: "capitalize",
              width: "100%",
            }}
            onClick={() => window.open("https://visionudbhav.org/", "_blank")}
          >
            Visit Udbhav
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export default CartPage;
